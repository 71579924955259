import React from "react";
import {withRouter} from "react-router-dom";
import {LayoutWithHeader} from "../components/LayoutWithHeader";
import {useAuth} from "../contexts/auth";

const HistoryInner = () => {
	const authContext = useAuth()
	return (
		<>
			<LayoutWithHeader title={"History"}>
				<div>Not Implemented Yet.</div>
			</LayoutWithHeader>
		</>
	)
}


export const History = withRouter(HistoryInner);