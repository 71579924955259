import React from "react";
import './gs.css';
import './gs_add.css';
import {Layout} from "./Layout";
import {Playlist} from "./Playlist";
import {ContentList} from "./ContentList";
import {PlaylistTop} from "./PlaylistTop";
import {HoverScrubber} from "./Scrubber";
import {AccountActivation} from "./AccountActivation";
import {Login} from "../pages/Login";
import {ScrubberLine} from "./ScrubberLine";
import {getDataByUniq} from "./utils";
import {VersionToggle} from "./withVersion";
import { BrowserRouter as Router, Redirect, Route, Switch, Link, withRouter, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import {useData} from "../contexts/data";
import {RequireAuth, useAuth} from "../contexts/auth";
import {Logout} from "../pages/Logout";
import {VideoCreate} from "../pages/VideoCreate";
import {VideoRecorder} from "../pages/VideoRecorder";
import {VideoViewer} from "../pages/VideoViewer";
import { Profile } from "../pages/Profile";
import {Search} from "../pages/Search";
import {History} from "../pages/History";
import {Bookmarks} from "../pages/Bookmarks";
import {Feedback} from "../pages/Feedback";
import {Users} from "../pages/Users";
import {Shared} from "../pages/Shared";
import { NewSpace } from './Onboarding/NewSpace';
import { DownloadApp } from './DownloadApp';

export const Routes = (props) => {
  // const history = useHistory();
  // const match = useRouteMatch();
  // const location = useLocation();


	const dataContext = useData();
	const authContext = useAuth()

	const spriteUrl = "https://res.cloudinary.com/generalsymbol/image/upload/v1639770770/samples/out_vkcckn.jpg"
	return (<>
		<VersionToggle/>
    <Router>
			<Switch>
        <Route exact path="/" render={() => <Redirect to="space/smartcaptures" />} />


        {/* DESKTOP APP */}
        <Route exact path="/download">
          <Layout >
            <DownloadApp />
          </Layout>
        </Route>

        {/* AUTH */}
				<Route exact path="/login">
					<Login/>
				</Route>
				<Route exact path="/logout">
					<RequireAuth>
						<Logout/>
					</RequireAuth>
				</Route>
				<Route exact path="/createaccount">
					<Login create/>
				</Route>
				<Route exact path="/forgotpassword">
					<Login forgot/>
				</Route>
				<Route path="/account_activation/:token" children={({match}) => (
					<AccountActivation token={match.params['token']}/>
				)}/>


        {/* VIDEO */}
				<Route path="/video_create">
					<RequireAuth>
						<VideoCreate/>
					</RequireAuth>
				</Route>
				<Route path="/video/:videoID/record" children={({match}) => (
					<RequireAuth>
						<VideoRecorder videoID={match.params['videoID']}/>
					</RequireAuth>
				)}/>
				<Route path="/video/:videoID" children={({match}) => (
					<RequireAuth>
						<VideoViewer videoID={match.params['videoID']}/>
					</RequireAuth>
        )} />
				<Route path="/:space/smartcaptures/:videoId?" render={({match}) =>
					<RequireAuth>
						<Layout videoId={match.params['videoId']}>
							<PlaylistTop title='Smart captures' emoji='🎦'/>
							<ContentList space={match.params["space"]} videoId={match.params['videoId']} userId={authContext.session?.user?.id}/>
						</Layout>
					</RequireAuth>
				}/>



				{/*<Route exact path="/:space/list/:playlistId?/:videoId?" render={({match}) =>*/}
				{/*	<RequireAuth>*/}
				{/*		<Layout videoId={match.params['videoId']}>*/}
				{/*			<Playlist*/}
				{/*				playlistId={dataContext.handles.getPlaylistIdFromName(match.params['playlistId'])}/>*/}
				{/*		</Layout>*/}
				{/*	</RequireAuth>*/}
				{/*}/>*/}
				{/*<Route path=":space/list/:playlistId?" render={({match}) =>*/}
				{/*	<RequireAuth>*/}
				{/*		<Layout>*/}
				{/*			<Playlist playlistId={dataContext.handles.getPlaylistIdFromName(match.params['playlistId'])}/>*/}
				{/*		</Layout>*/}
				{/*	</RequireAuth>*/}
				{/*}/>*/}

        {/* SPACE / NAVIGATION */}
				<Route path=":space/projects">
					<RequireAuth>
						{/*<Projects/>*/}
					</RequireAuth>
				</Route>
				<Route path=":space/settings">
					<RequireAuth>
						{/*<Projects/>*/}
					</RequireAuth>
				</Route>
				<Route path="/:space/search" render={({match}) => (
					<RequireAuth>
						<Search space={match.params.search}/>
					</RequireAuth>
        )}>
				</Route>
				<Route path="/:space/history">
					<RequireAuth>
						<History/>
					</RequireAuth>
				</Route>
				<Route path="/:space/bookmarks">
					<RequireAuth>
						<Bookmarks/>
					</RequireAuth>
				</Route>


        {/* SETTINGS */}
				<Route path="/:space/settings/users">
					<RequireAuth>
						<Users/>
					</RequireAuth>
				</Route>
				<Route path="/:space/shared">
					<RequireAuth>
						<Shared/>
					</RequireAuth>
				</Route>



        <Route path="/new-space">
          <RequireAuth>
            <NewSpace />
          </RequireAuth>
        </Route>


        {/* MENU / TOOLS */}
				<Route path="/profile">
					<RequireAuth>
						<Profile/>
					</RequireAuth>
				</Route>

				<Route path="/feedback">
					<RequireAuth>
						<Feedback/>
					</RequireAuth>
				</Route>
        <Route exact path="/clear" render={({ history }) => {
          localStorage.clear();
          history.push("");
          return null;
        }} />


        {/* OBSOLETE ROUTES */}
        <Route path="/scrubber">
          <RequireAuth>
            <div style={{ padding: 20 }}>
              <HoverScrubber width={240} height={172} sprite={spriteUrl} />
            </div>
          </RequireAuth>
        </Route>
        <Route path="/scrubberline">
          <RequireAuth>
            <div style={{ padding: 20 }}>
              <ScrubberLine />
            </div>
          </RequireAuth>
        </Route>
        <Route path="/videoscrubber" render={() =>
          <RequireAuth>
            <div style={{ padding: 20 }}>
              <HoverScrubber width={240} height={172} dd sprite={spriteUrl} />
            </div>
          </RequireAuth>
        } />
        <Route exact path="/calendar/today">
          <Redirect to={`/`} />
        </Route>
        <Route path="/calendar/:year?/:month?/:day?">
          <Layout />
        </Route>


			</Switch>
		</Router>
	</>);
}
