import React, { Fragment, useEffect, useState, useContext } from "react";
import { UserBadge } from './UserBadge';
import { useAuth } from "../contexts/auth";
import moment from "moment";
import { UIselect } from '../uikit/UIselect';
import uniqid from "uniqid";



//TODO: pass this structure to the Select element
export const defaultPlaylists = [
  {
    uniq: 'playlist_url_slug1',
    title: 'Best bevels',
    value: 1,
  },
  {
    uniq: 'playlist_url_slug2',
    title: 'Team RC car highlights',
    value: 2,
  },
  {
    uniq: 'playlist_url_slug3',
    title: 'Playlist 3',
    value: 3,
  },
]





export function PlaylistEntry({
  title = 'Playlist',
  uniq = 'playlist_url_slug',
  active,
}) {
  return (
    <>
      <div className="x_playlist_added"><div className="x_playlist_added_ico"><div className="ico"><div>playlist_add_check</div></div></div><div className="x_button_labels">{title}</div></div>
    </>
  )
}

export function PlaylistEntryItem({
  title = 'Playlist',
  uniq = 'playlist_url_slug',
  active,
}) {
  return (
    <>
      <div className="c_m_li">
        <div className="c_m_check">
          {active ? 'check_box' : 'check_box_outline_blank'}
        </div>
        <div className="c_m_check_label">
          {title}
        </div>
      </div>

    </>
  )
}




export function PlayerInfo({ video, playlists = defaultPlaylists }) {
  const authContext = useAuth();


  //TODO: dummy playlist handling
  const [activePlaylists, setActivePlaylists] = useState([])
  const playlistHandle = (value) => {
    let newPlaylistArray = JSON.parse(JSON.stringify(activePlaylists))

    if (newPlaylistArray.includes(value)) {
      newPlaylistArray.splice(newPlaylistArray.indexOf(value), 1)
    } else {
      newPlaylistArray.push(value)
    }
    setActivePlaylists(newPlaylistArray)
  }



  return (<>
    <div className="l_content_info">
      <div className="l_content_info_section">
        <div className="l_info_line">
          <div className="l_video_info_title">
            {video.title}
          </div>
        </div>
        <div className="l_info_line">
          <div className="l_player_actions">
            <div className="x_button_container_left">


              {/* <div className="x_button x_button--w x_button--active">
                          <div className="ico">
                              <div>bookmark</div>
                          </div>
                          <div className="x_button_labels">Bookmarked</div>
                      </div> */}


              <UIselect
                dropStyle={{
                  padding: "0.1em",
                }}
                dropItemStyle={{
                  borderRadius: "0.2em",
                  padding: "0.4em 1em",
                  margin: "0.2em",
                  minHeight: 32,
                }}
                multiSelect={true}
                width={220}
                // size="sm"
                placeholder="Add to playlist"
                // active = {filter.length ? filter[0] : null }
                active={activePlaylists}
                itemCustom={(item, active) => <PlaylistEntryItem {...item} active={active} />}
                itemActiveCustom={(item) => <PlaylistEntry {...item} />}
                menuHandler={(value) => {
                  playlistHandle(value)
                }}
                theme={{
                  // radius: '10px',
                  fontSize: 15
                }}
                options={playlists}
              />



              {/* <div className="x_button x_button--w">
                          <div className="ico">
                              <div>playlist_add</div>
                          </div>
                          <div>
                              <div className="x_button_labels">Add to playlist</div>
                          </div>
                      </div> */}



            </div>
          </div>
        </div>
        <div className="l_info_line">
          <div className="l_info_row">
            <div className="l_info_label">
              <div>Author:</div>
            </div>
            <div className="l_info_value">
              <UserBadge user={video.created_by} />
            </div>
          </div>

          <div className="l_info_row">
            <div className="l_info_label">
              <div>Created:</div>
            </div>
            <div className="l_info_value">
              {moment(video.created_at).fromNow()}
            </div>
          </div>
          {/* <div className="l_info_row">
  <div className="l_info_label">
    <div>Published:</div>
  </div>
  <div className="l_info_value">
    {date}
  </div>
</div> */}
          {/*<div className="l_info_row">*/}
          {/*	<div className="l_info_label">*/}
          {/*		<div>Owner:</div>*/}
          {/*	</div>*/}
          {/*	<div className="l_info_value">*/}
          {/*		<UserBadge*/}
          {/*			userId={userId}*/}
          {/*		/>*/}
          {/*	</div>*/}
          {/*</div>*/}


          <div className="l_info_row">
            <div className="l_info_label">
              <div>Access level:</div>
            </div>
            <div className="l_info_value">
              <div className="x_user0">
                <div className="x_access_icon">
                  <div className="ico">
                    <div>visibility</div>
                  </div>
                </div>
                <h5 className="x_user_name">
                  <span>
                    <strong>Everybody</strong>
                  </span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>


      {authContext.session?.user?.id === video.created_by.id &&
        <div className="l_content_info_section l_content_info_section--private">
          <div>
            <div className="l_info_section_title">
              <div>My content actions</div>
            </div>
            <div className="l_player_actions">
              <div className="x_button_container_left">
                <div className="x_button x_button--w">
                  <div className="ico">
                    <div>unpublished</div>
                  </div>
                  <div className="x_button_labels">Unpublish</div>
                </div>
                <div className="x_button x_button--w">
                  <div className="ico">
                    <div>delete</div>
                  </div>
                  <div>
                    <div className="x_button_labels">Delete</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
    </div>
  </>);
}
