import videojs from "video.js";

import {IDENTIFIER_1X} from "../../constants";
import {showQualitySelector} from "../../utils";
import QualitySelectorMenu from "./QualitySelectorMenu";

const Plugin = videojs.getPlugin("plugin");

class QualitySelector extends Plugin {
  constructor(player, options) {
    super(player, options);

    /*
      currentFile is the filename string of the mp4 source which is active.
      It is used to check which file is playing at an instance.
    */
    player.currentFile = IDENTIFIER_1X;
    videojs.registerComponent("QualitySelectorMenu", QualitySelectorMenu);
    showQualitySelector(player);
  }
}

videojs.registerPlugin("qualitySelector", QualitySelector);
