import videojs from "video.js";

import {showSpeedSelector} from "../../utils";
import SpeedSelectorMenu from "./SpeedSelectorMenu";

const Plugin = videojs.getPlugin("plugin");

class SpeedSelector extends Plugin {
  constructor(player, options) {
    super(player, options);

    /*
      displaySpeed is the integer value of playback speed shown in the player UI.
      It can also be used to check which playback-speed file is currently playing.
    */
    player.displaySpeed = 1;
    videojs.registerComponent("SpeedSelectorMenu", SpeedSelectorMenu);
    showSpeedSelector(player);
  }
}

videojs.registerPlugin("speedSelector", SpeedSelector);
