import React from "react";
import {ThemeProvider} from 'styled-components';
import './gs.css';
import './gs_add.css';
import {Routes} from "./Routes";
import {useData} from "../contexts/data";
import {Preloader, Preloader0} from "../uikit/Uikit";

export function Home() {
	const {ready} = useData()
	return (<>
		{!ready && (
			<Preloader0>
				<Preloader width={120} height={120}/>{" "}
			</Preloader0>
		)}
		{ready &&
			<ThemeProvider theme={{mode: 'personal'}}>
				<Routes/>
			</ThemeProvider>
		}
	</>);
}