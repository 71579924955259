import {useAuth} from "../contexts/auth";
import {Redirect} from "react-router-dom";
import {useEffect} from "react";

const Logout = (props) => {
	const authContext = useAuth()
	useEffect(() => {
		authContext.handles.logout()
	})
	return (
		<>
			<Redirect to={"/login"}/>
		</>
	)
}

export {Logout}