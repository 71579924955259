import React, {useState} from 'react';
import {gql} from "@apollo/client";
import {useApi} from "./api";
import {data} from '../components/data'

const DataContext = React.createContext()
const useData = () => React.useContext(DataContext)

const QUERY_VIDEO_BY_ID = gql`
    query ($id: String!) {
        videoByID(id: $id) {
            id,
            title,
            status,
            created_at
            created_by {
                id,
                name,
                username,
                email,
                created_at
            }
        }
    }
`;

const QUERY_USER_SPACE_MEMBERSHIPS = gql`
    query ($limit: Int!) {
        userSpaceMemberships(liimt: $limit) {
            id,
            role,
            belongs_to_space {
                id,
                name
            }
        }
    }
`;

const QUERY_VIDEO_SOURCE = gql`
    query ($id: String!) {
        videoSource(id: $id)
    }
`;

const QUERY_VIDEOS = gql`
    query ($offset: Int!, $limit: Int!, $reverse: Boolean!) {
        videos(offset: $offset, limit: $limit, reverse: $reverse) {
            id,
            title,
            size,
            duration,
            width,
            height,
            fps,
            created_at,
            status,
            created_by {
                id,
                name,
                username,
                email,
                created_at
            }
        }
    }
`;

const MUTATION_CREATE_VIDEO = gql`
    mutation ($title: String!) {
        createVideo(title: $title) {
            id,
            title,
            status,
            created_at,
            created_by {
                id,
                name,
                username,
                email,
                created_at
            }
        }
    }
`;

const QUERY_USER_BY_ID = gql`
    query ($id: String!) {
        userByID(id:$id) {
            id,
            name,
            username,
            email,
            created_at
        }
    }`;

const DataProvider = ({children}) => {
    const api = useApi()
    const [users, setUsers] = useState([])
    const [playlists, setPlaylists] = useState([])
    const [metadata, setMetadata] = useState([])
    const [projects, setProjects] = useState(data.projects)
    const [metaDataTypes, setMetaDataTypes] = useState([])
    const [apps, setApps] = useState([])

    const [ready, setReady] = useState(true)

    const createVideo = ({title}) => {
        return new Promise(async (resolve, reject) => {
            try {
                const result = await api.mutate({
                    mutation: MUTATION_CREATE_VIDEO, variables: {
                        title,
                    }
                })
                resolve(result.createVideo)
            } catch (err) {
                reject(err)
            }
        })
    }

    const videoByID = (id) => {
        return new Promise(async (resolve, reject) => {
            try {
                const result = await api.query({
                    query: QUERY_VIDEO_BY_ID, variables: {id}
                })
                resolve(result.videoByID)
            } catch (err) {
                reject(err)
            }
        })
    }

    const userByID = (id) => {
        return new Promise(async (resolve, reject) => {
            try {
                const result = await api.query({
                    query: QUERY_USER_BY_ID, variables: {id}
                })
                resolve(result.userByID)
            } catch (err) {
                reject(err)
            }
        })
    }

    const videoSource = (id) => {
        return new Promise(async (resolve, reject) => {
            api.query({
                query: QUERY_VIDEO_SOURCE, variables: {id}
            })
                .then((result) => resolve(result.videoSource))
                .catch((err) => reject(err))
        })
    }

    const playlistRename = (newName) => {
        alert(`New Playlist Name = "${newName}"`)
    };

    const videos = ({offset = 0, limit = 10, reverse = false}) => {
        return new Promise(async (resolve, reject) => {
            try {
                const result = await api.query({
                    query: QUERY_VIDEOS, variables: {offset, limit, reverse}
                })
                resolve(result.videos)
            } catch (err) {
                reject(err)
            }
        })
    }

    const userSpaceMemberships = ({limit = 10}) => {
        return new Promise(async (resolve, reject) => {
            try {
                const result = await api.query({
                    query: QUERY_USER_SPACE_MEMBERSHIPS, variables: {limit}
                })
                resolve(result.userSpaceMemberships)
            } catch (err) {
                reject(err)
            }
        })
    }

    const context = {
        ready,
        data: {
            playlists,
        },
        handles: {
            createVideo,
            userSpaceMemberships,
            videoByID,
            videoSource,
            userByID,
            playlistRename,
            videos,
        }
    }

    return (<DataContext.Provider value={{...context}}>
        {children}
    </DataContext.Provider>)
}

export {DataProvider, useData}