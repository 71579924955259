import {useAuth} from "../contexts/auth";
import React, {useEffect, useState} from "react";
import {API_URL} from "../config";
import useWebSocket, {ReadyState} from "react-use-websocket";
import {useData} from "../contexts/data";
import {UIbutton} from "../uikit/Uikit";
import {LayoutWithHeader} from "../components/LayoutWithHeader";
import {Preloader} from "../uikit/Preloader";
import {useHistory, withRouter} from "react-router-dom";

const VideoRecorderInner = ({videoID}) => {
	const dataContext = useData()
	const authContext = useAuth()
	const [isCaptureStarted, setCaptureStarted] = useState(false)
	const [recorder, setRecorder] = useState(null)
	const [video, setVideo] = useState(null)
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(Boolean(videoID))
	const [readyForView, setReadyForView] = useState(false)
	const history = useHistory()

	const wsURL = API_URL.replace("http://", "ws://").replace("https://", "wss://") + "/api/v1/ws"

	const {
		sendMessage, lastMessage, readyState,
	} = useWebSocket(wsURL, {
		shouldReconnect: (closeEvent) => true, onOpen: () => {
			sendMessage(JSON.stringify({
				action: "auth", payload: authContext.session.token
			}))
		},
	});

	const connectionStatus = {
		[ReadyState.CONNECTING]: 'Connecting',
		[ReadyState.OPEN]: 'Open',
		[ReadyState.CLOSING]: 'Closing',
		[ReadyState.CLOSED]: 'Closed',
		[ReadyState.UNINSTANTIATED]: 'Uninstantiated',
	}[readyState];

	useEffect(() => {
		dataContext.handles.videoByID(videoID).then((result) => {
			setVideo(result)
			sendMessage(JSON.stringify({
				action: "stream_init", payload: result.id
			}))
		}).catch((err) => {
			setError(err.message)
		}).finally(() => {
			setLoading(false)
		})
	}, [videoID])


	const captureScreen = async () => {
		const mediaConstraints = {
			audio: {
				echoCancellation: true, noiseSuppression: true, sampleRate: 44100
			}, video: {
				cursor: 'always', resizeMode: 'crop-and-scale'
			}
		}
		const stream = await navigator.mediaDevices.getDisplayMedia(mediaConstraints)
		return stream
	}

	const handleStartCapture = async () => {
		setError(null)
		if (isCaptureStarted) {
			handleStopCapture()
		}
		const _stream = await captureScreen()
		const video = document.getElementById('video')
		video.src = _stream
		const recorderOptions = {}
		const _recorder = new MediaRecorder(_stream, recorderOptions)
		let total_size = 0;
		_recorder.ondataavailable = async event => {
			total_size += event.data.size
			console.log('new chunk', event.data.size, total_size, event)
			if (event.data.size > 0) {
				let reader = new FileReader();
				reader.readAsArrayBuffer(event.data);
				reader.onloadend = async function (event) {
					let uint8View = new Uint8Array(reader.result);
					let data = Buffer.from(uint8View).toString("base64")
					sendMessage(JSON.stringify({action: 'stream_buffer', payload: data}))
				}
			}
		}
		_recorder.onstop = () => {
			console.log('recorder stopped')
			sendMessage(JSON.stringify({action: 'stream_close'}))
			handleStopCapture()
		}
		setRecorder(_recorder)
		setCaptureStarted(true)
		_recorder.start(200)
	}

	const handleStopCapture = () => {
		console.log('handle stop capture')
		if (recorder) {
			recorder.stream.getTracks().forEach(track => track.stop())
		}
		setRecorder(null)
		setCaptureStarted(false)
		setReadyForView(true)
	}

	const handleVideoView = () => {
		// history.push('/video/' + video.id + "/view")
    history.push("/space/smartcaptures/" + videoID)
	}

	return (<LayoutWithHeader title={"Video Recorder"}>
		{loading && (<Preloader>
			<Preloader/>
		</Preloader>)}
		{!loading && (<>
			<div className="line">
				<h3>{video?.title}</h3>
			</div>
			{/*<div className="line">*/}
			{/*	<h5>The WebSocket is currently {connectionStatus}</h5>*/}
			{/*</div>*/}
			{error && (<div className="line">
				<h5>ERROR: {error}</h5>
			</div>)}
			<div className="line">
				<video id="video" style={{backgroundColor: 'black', width: '640px', height: '480px'}}/>
			</div>
			{readyState == ReadyState.OPEN && !readyForView && (<div className="line line--center">
				{!isCaptureStarted && (<UIbutton onClick={handleStartCapture}>Start Capture</UIbutton>)}
				{isCaptureStarted && (<UIbutton onClick={handleStopCapture}>Stop Capture</UIbutton>)}
			</div>)}

			{readyForView && (
				<div className="line line--center">
					<UIbutton onClick={handleVideoView}>Watch Video</UIbutton>
				</div>
			)}
		</>)}
	</LayoutWithHeader>)
}

export const VideoRecorder = withRouter(VideoRecorderInner);
