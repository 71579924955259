import videojs from "video.js";

import {MP4_SOURCE_TYPE} from "../../constants";
import {getSrcUrl} from "../../utils";

const MenuItem = videojs.getComponent("MenuItem");

class QualitySelectorItem extends MenuItem {
  constructor(player, options) {
    super(player, options);
    this.selectable = true;
    this.update();
    this.addClass("vjs-quality-item");
  }

  handleClick() {
    const fileName = this.options_.fileName;
    const srcUrl = getSrcUrl(this.player().src(), fileName);
    const timestamp = this.player().currentTime();

    if (!srcUrl || this.player().currentFile === fileName) {
      return;
    }

    this.player().src({
      src: srcUrl,
      type: MP4_SOURCE_TYPE,
    });

    this.player().currentFile = fileName;
    this.player().currentTime(timestamp);
    this.player().play();

    this.options_.controller.triggerItemUpdate();
  }

  update() {
    this.selected(this.player().currentFile === this.options_.fileName);
  }
}

export default QualitySelectorItem;
