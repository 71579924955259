import React from "react";





export const UIbuttonGhost = ({
  className, title = '', icon = '', iconBefore = false, onClick = () => void (0),
}) => {
  return (<>
    <div className={"x_button x_button--cover " + className}
      onClick={(e) => onClick(e)}
    >
      {Boolean(title) && <div className="x_button_label">{title}</div>}
      {Boolean(icon) && <div className="x_button_icon">{icon}</div>}
    </div>

  </>);
};
