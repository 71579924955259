import { Link, useHistory, useRouteMatch } from "react-router-dom";
import React from "react";

export const MenuLink = (props) => {
  const { title, icon, to, additionalClass = '', onClick, forceUpdate, active } = { ...props }
  const history = useHistory()

  return (<>
    {to && <Link to={to} style={{ width: '100%' }} className="l_c_menu_li">

      <div className={`l_c_menu_item ${active ? 'active' : ''} ${additionalClass}`}>
        <MenuLinkInner
          {...props}
        />
      </div>

    </Link>}

    {!to && onClick &&
      <div className="l_c_menu_li">

        <div
          className={`l_c_menu_item ${active ? 'active' : ''} ${additionalClass}`}
          onClick={() => onClick ? onClick() : {}}
        >
          <MenuLinkInner
            {...props}
          />
        </div>
      </div>}
  </>
  )
}


function MenuLinkInner(props) {
  const { title, icon, to, onClick, quantity } = { ...props }
  return (
    <>
      <div className="l_c_menu_item_left">
        <div className="l_c_menu_ico">
          <div className="ico">
            {icon == 'user-avatar' && <div className="m_user_avatar" />}
            {icon != 'user-avatar' && <div>{icon}</div>}
          </div>
        </div>
        <div className="l_c_menu_label">
          {title}
        </div>
      </div>
      <div className="l_c_menu_item_right">
        {typeof (quantity) !== 'undefined' && <div className="l_c_menu_item_num">{quantity}</div>}
      </div>
    </>
  )
}
