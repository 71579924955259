import React, { useEffect, useState } from "react";
import { getDataById, getTime, getMetadata, getMetadataWithOrder } from './utils';
import "react-scrubber/lib/scrubber.css";
import { Scrubber } from "react-scrubber";
import { MetadataComments, MetadataCommentsLine } from "./MetadataComments";
import styled from "styled-components";
import { Slider } from "./Slider";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { useData } from "../contexts/data";
import { UIscrubber } from '../uikit/UIscrubber';
import { MetadataAddTagForm } from './MetadataAddTagForm';

export const MetadataSection = React.memo(
  ({
    videoId,
    typeRef,
    typeTitle,
    history,
    match,
    location,
    frame,
    handleFrame,
  }) => {
    const dataContext = useData();
    const [viewType, setViewType] = useState("slider"); //slider/line;
    const [taggingInProgress, setTaggingInProgress] = useState(false); //slider/line;
    const typeData = getDataById(dataContext, "metaDataTypes", typeRef) || {};
    const videoData = getDataById(dataContext, "videos", videoId) || {};
    const color = typeData['color'] || '#06a19a';

    const [comment, setComment] = useState('')


    const handleTagAdd = () => {
      //TODO: create a tag on the backend
      alert(`Tag with comment ${comment} at time ${getTime(frame, videoData['length'])} `)
    }


    const protectUserInput = (input) => {
      //TODO: sanitize user's input (and on backend too)
      const source = typeof input === 'string' || input instanceof String ? input : '';
      return source.replace(/[-[/\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    useEffect(() => {
      handleFrame(0);
    }, [videoId]);

    return (
      <div className="l_comments_section">
        <div className="l_comments_container">


          <div className="x_meta_sticky">


            <div
              className="x_meta_title0"
            // style={{ color: color }}
            >
              <div
                className="x_meta_tag x_meta_tag--title"
                style={{ color: color }}
              >
                {Boolean(typeData["emoji"]) && <div className="x_meta_icon">
                  <div>
                    <div className="p_player_emoji">{typeData["emoji"]}</div>
                  </div>
                </div>}


                {/* Title */}
                <div className="x_meta_title">
                  {Boolean(typeData["title"])
                    ? typeData["title"]
                    : "Highlights"}
                  <div className="x_meta_title_nums0">
                    <div className="x_meta_title_num">
                      {getMetadata(videoId, typeRef).length}
                    </div>
                  </div>
                </div>

                {/* If there are no tags, the button is hidden */}
                {Boolean(getMetadata(videoId, typeRef).length) && <ShowAddTagButton
                  frame={frame}
                  length={videoData['length']}
                  taggingInProgress={taggingInProgress}
                  setTaggingInProgress={setTaggingInProgress}
                  color={color}
                />}



              </div>

              <div className="x_meta_right">
                {/* Auxiliary Actions Container */}
              </div>
            </div>

            <div className="p_player_scrubber0">


              <ScrubberMarkers
                tagsArray={getMetadata(videoId, typeRef)}
                color={color}
                handleFrame={handleFrame}
              />




              <UIscrubber
                frame={frame}
                color={color}
                handleFrame={handleFrame}
                tagging={taggingInProgress || !Boolean(getMetadata(videoId, typeRef).length)}
                handleClick={setTaggingInProgress}
              />

            </div>

            <MetadataAddTagForm
              comment={comment}
              setComment={(val) => setComment(protectUserInput(val))}
              taggingInProgress={taggingInProgress}
              typeData={typeData}
              handleTag={() => {
                handleTagAdd()
                setTaggingInProgress(false)
              }}
              //TODO: get formatted video length
              time={getTime(frame, videoData['length'])}
              handleClose={getMetadata(videoId, typeRef).length
                ? () => setTaggingInProgress(false)
                : null
              }
            />

          </div>



          <div className="c_player_scrub">
            <div className="c_player_container">
              <div className={`p_comments0 p_comments0--collapsible shown`}>

                <div className="c_player_comment0">
                    <div className="p_comments0 p_comments0--slider">
                      <Slider
                        handleClick={(metaTime) => handleFrame(metaTime * 100)}
                      array={getMetadata(dataContext, videoId, typeRef)}
                      activeSlideKey={getMetadataWithOrder(dataContext, videoId, typeRef, frame)}
                      ></Slider>
                  </div>
                </div>

                <div className="c_player_comment0">
                  <div className="p_comments0">
                    {getMetadata(dataContext, videoId, typeRef).map((oneMetadata) => {
                      // console.log("oneMetadata = ", oneMetadata);
                      return (
                        <>
                          {oneMetadata && oneMetadata["commentText"] && (
                            <div
                              key={`metadata_${oneMetadata["metaTime"]
                                  ? oneMetadata["metaTime"]
                                  : 0
                                }`}
                            >
                              {viewType === "slider" && (
                                <MetadataComments {...oneMetadata} />
                              )}
                              {viewType === "line" && (
                                <MetadataCommentsLine {...oneMetadata} />
                              )}
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);







const ScrubberMarkers = ({ color, tagsArray = [], handleFrame }) => {
  return (
    <div className="p_player_scroll_line">
      {tagsArray.map((oneMetadata) => {
        let metaTime = oneMetadata["metaTime"];
        return (
          <div
            key={`metadata_${oneMetadata["id"]}`}
            onClick={() => handleFrame(metaTime * 100)}
            className="p_player_comment0"
            style={{
              marginLeft: `${metaTime * 100 - 2}%`,
            }}
          >
            <div
              className="p_player_comment_glow"
              style={{
                backgroundColor: color,
              }}
            />
            <div
              className="p_player_comment"
              style={{
                backgroundColor: color,
              }}
            ></div>
          </div>
        );
      })}
    </div>
  )
}


const ShowAddTagButton = ({ frame, color, taggingInProgress, setTaggingInProgress, length }) => {
  return (
    <div className="x_meta_title_add0"
      style={{ color: color }}
      onClick={() => setTaggingInProgress(!taggingInProgress)}
    >
      <div className="x_button x_button--micro">
        <div className="ico">
          {taggingInProgress && <div>close</div>}
          {!taggingInProgress && <div>add</div>}
        </div>
        <div className="x_button_colored_bg" />
      </div>
      {!taggingInProgress && <div className="x_meta_title_add_hint">
        <div>Add tag at
          {/* TODO: Current time */}
          {getTime(frame, length)}
        </div>
      </div>}
    </div>
  )
}


const CSSscrubberContainer = styled.div`
  margin-top: -24px;
`

const CSSaddPointContainer = styled.div`
  position: relative;
  margin-top: -1px;
  width: 100%;
  height: 1px;
`;
const CSSaddPointButton = styled.div`
  width: 24px;
  height: 24px;
  // background-color: ${({ color }) => `${color}`};
  background-color: white;
  transition: transform 150ms ease, opacity 200ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 24px;
  cursor: pointer;
  border-radius: 0.8em;
  opacity: 0;
`;

const CSSaddPoint = styled.div`
  & .cssaddpointbutton {
    /* transform: scale(0); */
    /* opacity: 0; */
  }
  &:hover .cssaddpointbutton {
    transform: scale(1) !important;
    opacity: 1 !important;
  }
  position: absolute;
  top: 0;
  height: 44px;
  width: 44px;
  z-index: 1;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  margin-left: -22px;
  left: ${({ left }) => `${left}%`};
`;



export const CSSscrubberStyle = styled.div`

  &:hover .cssaddpointbutton {
    transform: scale(0.6);
    opacity: 0.3;
  }

  /* cursor: pointer; */
  & .bar__progress {
    background-color: ${({ color }) => color} !important;
    opacity: 0.6;
  }
  & .bar {
    height: 24px !important;
    background-color: transparent;
    top: 0 !important;
    left: 0;
    transform: translateY(0) !important;
  }
  & .bar {
    /* transform: translateY(-24px) !important; */
  }

  & .bar::after {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: ${({ color }) => color} !important;
    opacity: 0.2;
  }
  & .bar__thumb {
    background-color: ${({ color }) => color} !important;
  }
  & .hover .bar__thumb {
    width: 16px;
    height: 32px;
    transition: transform(100ms);
    z-index: 1;
    background: ${({ color }) => color} !important;
  }

  /* & .scrubber.hover .bar__marker {
    width: 4px;
    height: 24px;
    z-index: 2;
    opacity: 0.3;
    background-color: ${({ color }) => color} !important;
  } */

  & .scrubber.horizontal .bar__marker {
    opacity: 0.7;
    width: 4px;
    height: 24px;
    z-index: 2;
    background-color: ${({ color }) => color} !important;
    display: flex;
    justify-content: center;
  }
  & .bar__marker::after {
    content: "";
    opacity: 0;
    position: absolute;
    z-index: 2;
    width: 24px;
    height: 24px;
    background-color: ${({ color }) => color} !important;
    cursor: pointer;
    transition: transform 150ms ease, opacity 200ms ease;
  }
  & .bar__marker:hover::after {
    content: "";
    opacity: 0.4;
  }
`;
