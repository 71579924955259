import React, { useState, useEffect } from "react";
import { Axios } from "axios";
import { HIGHLIGHTS_ID } from '../config';

export const formatParamsToDate = (match) => {
    return `${match['params']['month']}/${match['params']['day']}/${match['params']['year']}`
}

const HIGHTLIGHTS_ID = "none"


export const GetDateFormat = (date) => {
    var month = (date.getMonth() + 1).toString();
    month = month.length > 1 ? month : '0' + month;
    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    // return month + '/' + day + '/' + date.getFullYear();
    return date.getFullYear() + '/' + month + '/' + day;
}

export const dateObj = new Date();
export const dateFormat = GetDateFormat(dateObj);


export const getImage = (userDataImg, size = 'small') => {
    return userDataImg && Object.keys(userDataImg).length ? userDataImg['thumbnails'][size]['url'] : ''
}



export const getDataById = (context, what, id, field) => {
    let a = context["data"][what].filter((item) => item["id"] === id)[0];
    if (field && a) { a = a[field] ? a[field] : null }
    return a;
};

export const getDataByUniq = (context, what, id, field) => {
    let a = context["data"][what].filter((item) => item["uniq"] === id)[0];
    if (field && a) { a = a[field] ? a[field] : null }
    return a;
};





export const getVideo = (youTubeUrl) => {
    // console.log('youTubeUrl = ', youTubeUrl);
    const url = `https://youtube-url-api.herokuapp.com/video_info.php?url=https://www.youtube.com/watch?v=${youTubeUrl}`

    const headers = { 'Content-Type': 'application/json' }

    fetch(url, {
        headers,
        method: 'GET',
        mode: 'no-cors'
    }, ).then(response => {
        console.log('response = ', response);
        if (response.ok) {
            response.json().then(json => {
                console.log('json = ', json);
                console.log('jsonlinks = ', json['links']);
            });
        }
        if (!response.ok) {
            // get error message from body or default to response statusText
            // const error = (response.json() && response.json().message) || response.statusText;
            // return Promise.reject(error);
        }
    });
    // https://youtube-url-api.herokuapp.com/video_info.php?url=https://www.youtube.com/watch?v=wJ-IbiITD7Y

}




export const getMetadataCategories = (
  context,
  videoId,
  sort = false,
  invert = false,
  addHighlights = false,
) => {
  if (!videoId) return [];
  let metaArrayIds = getDataById(context, "videos", videoId, "metadata") || [];

  let metaIdsAll = context.data['metaDataTypes'].map(one => one['id']);


  let metadataArray = metaArrayIds.map((oneId) => ({
        ...getDataById(context, "metadata", oneId),
    }));
    // console.log("metadataArray = ", metadataArray);
    let arr = [
        ...new Set(
            metadataArray.map((oneMeta) =>
                getDataById(context, "metadata", oneMeta["id"]) ?
                oneMeta["typeRef"] :
                null
            )
        ),
    ];

    if (sort) {
        arr = arr.sort((a, b) => {
            const getLength = (typeRef, videoId) => context.data.metadata.filter(oneMetadata => {
                let r = oneMetadata['typeRef'] === typeRef && oneMetadata['videoId'] === videoId
                return r;
            }).length;
            return getLength(b, videoId) - getLength(a, videoId)
        })
    }


  if (invert) {
    // used to show threads that have no entries for the video
    arr = metaIdsAll.filter(value => !arr.includes(value));
    arr = arr.filter(value => value !== HIGHTLIGHTS_ID);
  };

  if (addHighlights) {
    // add highlights thread even if it has no entries
    arr = arr.includes(HIGHTLIGHTS_ID) ? arr : [HIGHTLIGHTS_ID, ...arr]
    console.log('arr = ', arr);
  }

    return arr;
    // return [...new Set(metadataArray.map((oneMeta) => getDataById(context, 'metadata', oneMeta["typeRef"]) ? oneMeta["typeRef"] : null))];
    // return metadataArray.map(oneMeta => oneMeta)
    // return metadataArray ? metadataArray : []
};



export const getTime = (metaTime, length) => {
  let minutes = Math.floor(length / 100 * metaTime);
  return minToHours(minutes);
};




const sortMetadata = (array, frame) => {
  return array.sort((a, b) => {
    return (
      Math.abs(a["metaTime"] * 100) -
      frame -
      Math.abs(b["metaTime"] * 100 - frame)
    );
  });
};
//
// export const getMetadataWithOrder = (context, videoId, typeRef, frame) => {
//   if (!videoId || !typeRef) return [];
//
//   let metaArrayIds =
//     getDataById(context, "videos", videoId, "metadata") || [];
//   let metadataArray = metaArrayIds.map((oneId) => ({
//     ...getDataById(context, "metadata", oneId),
//   }));
//   // metadataArray = metadataArray.length ? metadataArray.filter(oneMetadata => oneMetadata['active']) : []
//   metadataArray = metadataArray.filter((oneMeta) => {
//     return typeRef && oneMeta["typeRef"] === typeRef;
//   });
//
//   metadataArray = metadataArray.map((one, ind) => ({
//     order: ind,
//     ...one,
//   }));
//
//   metadataArray = sortMetadata(metadataArray, frame);
//
//   // metadataArray = new Set(metadataArray);
//   metadataArray = metadataArray.filter((x) => typeof x !== "undefined");
//
//   // console.log("metadataArray = ", metadataArray);
//   return metadataArray.length ? metadataArray[0]["order"] : 0;
// };



export const getMetadata = (context, videoId, typeRef) => {
  if (!videoId || !typeRef) return [];

  let metaArrayIds =
    getDataById(context, "videos", videoId, "metadata") || [];

  if (!metaArrayIds) return [];
  // console.log("metaArrayIds = ", metaArrayIds);
  // debugger;

  let metadataArray = metaArrayIds.map((oneId) => {
    let ret = getDataById(context, "metadata", oneId);
    // debugger;
    return {
      ...ret,
    };
  });
  // metadataArray = metadataArray.length ? metadataArray.filter(oneMetadata => oneMetadata['active']) : []
  metadataArray = metadataArray.filter((oneMeta) => {
    return typeRef && oneMeta["typeRef"] === typeRef;
  });

  metadataArray = metadataArray.map((one, ind) => ({
    order: ind,
    ...one,
  }));
  // console.log('metadataArray = ', metadataArray);

  metadataArray = metadataArray
    .filter((x) => typeof x !== "undefined")
    .filter(() => true);

  // console.log("metadataArray = ", metadataArray);
  return metadataArray;
};



//Show closest TAG to a current frame
export const getMetadataWithOrder = (dataContext, videoId, typeRef, frame) => {
  if (!videoId || !typeRef) return [];

  let metaArrayIds =
    getDataById(dataContext, "videos", videoId, "metadata") || [];
  let metadataArray = metaArrayIds.map((oneId) => ({
    ...getDataById(dataContext, "metadata", oneId),
  }));
  metadataArray = metadataArray.filter((oneMeta) => {
    return typeRef && oneMeta["typeRef"] === typeRef;
  });

  metadataArray = metadataArray.map((one, ind) => ({
    order: ind,
    ...one,
  }));

  metadataArray = sortMetadata(metadataArray, frame);

  metadataArray = metadataArray.filter((x) => typeof x !== "undefined");

  return metadataArray.length ? metadataArray[0]["order"] : 0;
};





// const sortMetadata = (array, frame) => {
//   return array.sort((a, b) => {
//     return (
//       Math.abs(a["metaTime"] * 100) -
//       frame -
//       Math.abs(b["metaTime"] * 100 - frame)
//     );
//   });

  
export const handleUrl = (history, match, videoId, action, addToPlaylist) => {
  // history.push(`/list/${match.params["playlistId"]}/${id}`);
  // console.log(match);
  // console.log(history);
  // console.log(location);
  if (!addToPlaylist) {
    let path = match["path"];
    path = path.replace(":space", match.params["space"]);
    path = path.replace(":videoId?", videoId);
    if (path.charAt(path.length - 1) !== '/') {
      path += '/';
    }
    if (action) {
      path = path.replace(":action?", action);
    } else {
      path = path.replace(":action?/", '');
      path = path.replace(":action?", '');
    }

    if (match.params["playlistId"]) {
      path = path.replace(":playlistId?", match.params["playlistId"]);
      history.push(path);
    } else {
      history.push(path);
    }
  }

  if (addToPlaylist) {
    //TODO: add to the current playlist handler
  }
};


export const minToHours = (min) => {
  let hrs = min / 60;
  hrs = (hrs > 1) ? parseInt(hrs.toString().split('.')[0]) : 0;
  let minutes = min - (hrs * 60);
  if (minutes.toString().length === 1) minutes = '0' + minutes.toString();
  return hrs + ':' + minutes;
}


// export const getTime = (metaTime, length) => {
//   let minutes = Math.floor(length / 100 * metaTime);
//   return minToHours(minutes);
// };