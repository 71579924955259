import React, {useEffect, useState} from "react";
import {Preloader, Preloader0} from "../uikit/Uikit";
import {Link, withRouter} from "react-router-dom";
import {useAuth} from "../contexts/auth";

const AccountActivationInner = ({token}) => {
	const authContext = useAuth()
	const [completed, setCompleted] = useState(false);
	const [failed, setFailed] = useState(false);
	const [message, setMessage] = useState("")

	useEffect(() => {
		if (!completed) {
			authContext.handles.accountActivation(token).then((result) => {
				setMessage('Account activated')
			}).catch((err) => {
				setFailed(true)
				setMessage(err.message)
			}).finally(() => {
				setCompleted(true)
			})
		}
	}, [authContext.handles, completed, token])

	return (<>
		<AccountActivationLayout>
			<div className="flex vertical center middle">
				<div className="line line--center">
					<h1>Account Activation</h1>
					<div>
						{!completed && (
							<Preloader0>
								<Preloader width={60} height={60}/>{" "}
							</Preloader0>
						)}
						{completed && failed && (
							<>
								<h3>Error</h3>
								<div>{message}</div>
							</>
						)}
						{completed && !failed && (
							<>
								<h3>Congratulations!</h3>
								<div>{message}</div>
								<div><Link to={'/login'}>Login</Link></div>
							</>
						)}
					</div>
				</div>
			</div>
		</AccountActivationLayout>
	</>);
};

export function AccountActivationLayout({children}) {
	return (
		<>
			<div className="l_page0">
				<div className="l_page_top"/>
				<div className="l_page_b">{children}</div>
			</div>
		</>
	)
};

export const AccountActivation = withRouter(AccountActivationInner);