import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch, Link, withRouter, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { getDataById, getDataByUniq } from './utils';

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Select } from "../uikit/UIselect";
import { useData } from "../contexts/data";
import { useAuth } from "../contexts/auth";
import { MenuLink, MenuPlaylist } from './MenuLink';
import { MenuSpaceSelect } from './Menu/MenuSpaceSelect';
import { MenuTeams } from './MenuTeams';


export function MenuMain({
  space = 'company'//personal/company
}) {
  return (
    <>
      <div className="m_collapsible0">
        <div className="m0">
          <div className="m0_sticky">
            <MenuInner space />
          </div>
        </div>
        <div className="m0_collapsed">
          <div className="m0_sticky">
            <MenuInner space />
          </div>
        </div>
        <div className="m0_z" />
      </div>
    </>
  );
}

export function MenuInnerInner(props) {
  const { space } = { ...props };
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const authContext = useAuth();
  const dataContext = useData();
  const activeSpace = match.params['space'] ?? 'space';

  const grid = 8;
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // padding: grid * 2,
    // margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "transparent",

    // styles we need to apply on draggables
    ...draggableStyle
  });

  const onImgClick = () => {
    // console.log("image clicked")
    history.push("/")
    }

  const checkIfActive = (uniq) => location.pathname.indexOf(uniq) !== -1

  return (
    <>
      {<>
        <div className="m0_sticky">
        <div className="m_top">

        <div className="x_app_logo">
				<div className="x_logo_img">
					{/* <button> */}
					<img src="https://assets.website-files.com/627b3c839e056e0d434545cd/628f02573417e13b9bcae3d5_gs_app_img.png" onClick={onImgClick} width={42} height={42} alt="General Symbol Logo" />
					{/* </button> */}
					</div>

					<div className="x_logo_text">
					{/* <button> */}
					<img alt="General Symbol Text" src="https://assets.website-files.com/627b3c839e056e0d434545cd/628f025782fe4e4361fd568a_gs_app_logo.png" loading="lazy" width={166} height={42} onClick={onImgClick} />
					{/* </button> */}
					</div>
					</div>

          <div className="l_c_menu_section">

              <div className="l_c_menu_ul">
                <MenuLink
                  id='search'
                  to={`/${activeSpace}/search`}
                  title='Search'
                  icon='search'
                  active={checkIfActive('search')}
                // quantity={0}
                />

                {/*<MenuLink*/}
                {/*  id='history'*/}
                {/*  to={`/${activeSpace}/history`}*/}
                {/*  title='History'*/}
                {/*  icon='history'*/}
                {/*  active={checkIfActive('history')}*/}
                {/*// quantity={0}*/}
                {/*/>*/}

                <MenuLink
                  id='bookmarks'
                  to={`/${activeSpace}/bookmarks`}
                  title='Bookmark'
                  icon='bookmark'
                  active={checkIfActive('bookmarks')}
                // quantity={0}
                />

                <MenuLink
                  id='smartcaptures'
                  to={`/${activeSpace}/smartcaptures`}
                  title='All my content'
                  icon='smart_display'
                  active={checkIfActive('smartcaptures')}
                // quantity={0}
                />

                <MenuLink
                  id='shared'
                  to={`/${activeSpace}/shared`}
                  title='All shared content'
                  icon='folder_shared'
                  active={checkIfActive('shared')}
                  quantity={0}
                />



                <MenuLink
                  onClick={() => {
                    //TODO:  take playlist creation from the branch 'UI-player-add-to-playlists'
                    return null
                  }}
                  title={"Create playlist"} icon={"add"} additionalClass="l_c_menu_item--add"
                />
              </div>
            </div>



          {/* //TODO: Create space select */}
          {/* <div className="l_c_menu_info">
            <MenuSpaceSelect
              options={dataContext.data.spaces}
            />
          </div> */}






          <div className="l_c_menu_section">

              {/* <div className="l_c_menu_title">
              <div>Shared playlists</div>
              <div className="l_c_menu_add">
                <div className="ico">
                  <div>add</div>
                </div>
              </div>
            </div> */}



            <DragDropContext
            // onDragEnd={this.onDragEnd}
            >
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (

                  <div
                      className="l_c_menu_ul"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {dataContext.data["playlists"]
                      // .filter((one) => one['type'] === 'searchBased')
                      .filter((one) => {
                        let space = getDataById(dataContext, 'spaces', one['space'], 'uniq');
                        return space === activeSpace
                      })
                      .map((one, index) => (

                        <Draggable key={`playlist_${one["id"]}`} draggableId={`playlist_${one["id"]}`} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <MenuLink
                                active={match.params['playlistId'] === one["uniq"]}
                                // key={`playlist_${one["id"]}`}
                                {...one}
                                {...props}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>



            {/* <MenuLink
              title={"Create shared playlist"}
              icon={"add"}
              additionalClass="l_c_menu_item--add"
              onClick={() => {
                //TODO:  take playlist creation from the branch 'UI-player-add-to-playlists'
                return null
              }}
            /> */}
          </div>





          <div className="l_c_menu_section">

              {/* <div className="l_c_menu_title">
              <div>Shared space</div>
              <div className="l_c_menu_add">
                <div className="ico">
                  <div>add</div>
                </div>
              </div>
            </div> */}


            {/* TODO:// playback history, will implement later */}
            {/* <li className="l_c_menu_li">
              <MenuLink title="History" to={`/` + activeSpace + "/history"} icon="history"
                active={checkIfActive('history')}
              />
            </li> */}


            {/* TODO:// bookmarks, will implement later */}
            {/* <MenuLink
                title="Bookmarks"
                to={`/` + activeSpace + "/bookmarks"}
                icon="bookmarks"
                active={checkIfActive('bookmarks')}
                quantity={0}
              /> */}




            <DragDropContext
            // onDragEnd={this.onDragEnd}
            >
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (

                  <div
                    className="m_ul"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {dataContext.data["playlists"]
                      // .filter((one) => one['type'] === 'searchBased')
                      .filter((one) => {
                        let space = getDataById(dataContext, 'spaces', one['space'], 'uniq');
                        return space === activeSpace
                      })
                      .map((one, index) => (

                        <Draggable key={`playlist_${one["id"]}`} draggableId={`playlist_${one["id"]}`} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <MenuLink
                                active={match.params['playlistId'] === one["uniq"]}
                                to={`/${activeSpace}/list/${one['uniq']}`}
                                // key={`playlist_${one["id"]}`}
                                {...one}
                                {...props}
                                quantity={dataContext.data["playlists"]
                                  .filter((one) => {
                                    let space = getDataById(dataContext, 'spaces', one['space'], 'uniq');
                                    return space === activeSpace
                                  }).length
                                }
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>


          </div>


            <div className="l_c_menu_section l_c_menu_section--todo">
            <div className="l_c_menu_title">
              To do
            </div>

            <MenuLink
              title={"Try uploading a video"}
              icon={"cloud_upload"}
              to={"/video_create"}
              additionalClass="l_c_menu_item--add"
              forceUpdate
              active={checkIfActive('video_create')}
            />

            <MenuLink
              to={'/download'}
              title={"Download Desktop app"}
              icon={"download"}
              additionalClass="l_c_menu_item--add"
              active={checkIfActive('/download')}
            />

            <MenuLink
              to={'/' + activeSpace + '/settings/users'}
              title={"Invite teammates"}
              icon={"person_add"}
              additionalClass="l_c_menu_item--add"
              // active={checkIfActive('settings/users')}
            />

          </div>









        </div>

        {authContext.session && (
          <>
            <div className="m_bot">
              <div className="l_c_menu_section">
                <div className="l_c_menu_ul">
                  {/* <MenuLink title={"Settings"} icon={"tune"} to={"/settings"}
                    active={checkIfActive('settings')}
                  /> */}
                  <MenuLink
                    title={"Feedback"}
                    icon={"feedback"}
                    active={checkIfActive('feedback')}
                  />

                  <MenuLink
                    title="Users &amp; access"
                    icon="group"
                    to={'/' + activeSpace + '/settings/users'}
                    active={checkIfActive('settings/users')}
                  />


                  <MenuLink
                    title='Profile'
                    // title={authContext?.user?.email}
                    to="/profile"
                    // icon={"user-avatar"}
                    icon={"person"}
                    active={checkIfActive('profile')}
                  />
                  <MenuLink title={"Logout"} icon={"logout"} to={"/logout"}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        </div>
      </>}
    </>
  );
}



const MenuInner = withRouter(MenuInnerInner)

