import React, { useEffect, useState } from 'react'
import { useData } from "../contexts/data";
import { API_URL } from "../config";

export const UserBadge = ({ userID, type = 'md' }) => {
  const dataContext = useData();
  const [user, setUser] = useState();
  useEffect(() => {
    if (!userID) {
      return
    }
    dataContext.handles
      .userByID(userID)
      .then((value) => setUser(value))
      .catch((err) => console.log('UserBadge userByID error', err))
  }, [userID])

  return (
    <>
      {type === 'md' && user && (
        <div className="x_user0">
          {user.img && (
            <div className="x_user_ava">
              <img src={user.imageURL} loading="lazy" className="p_user_ava_img p_user_ava_img--sm" />
            </div>
          )}
          <h5 className="x_user_name">
            <span>
              <strong>{user.name}</strong>
            </span>
          </h5>
        </div>
      )}

      {type === 'sm' && <div className="ui_video_author">
        {user.img && (
          <div className="x_user_ava">
            <img
              src={user.imageURL}
              loading="lazy"
              className="p_user_ava_img p_user_ava_img--sm"
            />
          </div>
        )}
        <div className="x_user_name">
          <strong>{user['name']}</strong>
        </div>
      </div>}
    </>)
}