import React, { Fragment, useEffect, useState, useContext } from 'react'
import { Link, withRouter, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import styled, { css } from 'styled-components';

export function DownloadApp(props) {
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  return (
    <>
      <h1>
        Download General Symbol Desktop app
      </h1>
      <h3>
        Coming soon
      </h3>
    </>
  )
}