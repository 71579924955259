import React, { Fragment, useEffect, useState, useContext } from 'react'
import { Link, withRouter } from 'react-router-dom'
import styled, { css } from 'styled-components';
import { Scrubber } from 'react-scrubber';

export function UIscrubber({
  color,
  frame,
  handleFrame,
  tagging,
  handleClick
}) {
  return (
    <>

      <CSSscrubberContainer>
        <CSSscrubberStyle color={color}>
          {/* <div className="scrubber-container"> */}
          <CSSscrubber
            tagging={tagging}
            min={0}
            max={100}
            // value={frameLocal}
            value={frame}
            onScrubChange={handleFrame}
          />
          {!tagging && <CSSaddPointContainer onClick={() => handleClick(true)}>
            <CSSaddPoint left={frame}>
              <CSSaddPointButton className="cssaddpointbutton ico">
                add
              </CSSaddPointButton>
            </CSSaddPoint>
          </CSSaddPointContainer>}
        </CSSscrubberStyle>
      </CSSscrubberContainer>

    </>
  )
}


const CSSscrubber = styled(Scrubber)`
  ${({ tagging }) => tagging && css`
    & .bar__thumb {
      opacity: 1;
      border-radius: 100px;
      width: 16px !important;
      height: 32px;
      transition: transform(100ms);
      z-index: 1;
      background: ${({ color }) => color} !important;
      position: relative;
    }
    // & .bar__thumb::after {
    //   font-family: Materialicons, sans-serif;
    //   content: 'place';
    //   opacity: 0;
    //   position: absolute;
    //   left: 0;
    //   top:0;
    //   z-index: 2;
    //   width: 24px;
    //   height: 24px;
    //   color: white;
    //   transition: transform 150ms ease, opacity 200ms ease;
    // }
  `}
`



const CSSscrubberContainer = styled.div`
  margin-top: -24px;
`

const CSSaddPointContainer = styled.div`
  position: relative;
  margin-top: -1px;
  width: 100%;
  height: 1px;
  z-index: 11;
`;
const CSSaddPointButton = styled.div`
  width: 24px;
  height: 24px;
  // background-color: ${({ color }) => `${color}`};
  background-color: white;
  transition: transform 150ms ease, opacity 200ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 24px;
  cursor: pointer;
  border-radius: 0.8em;
  opacity: 0;
`;

const CSSaddPoint = styled.div`
  & .cssaddpointbutton {
    /* transform: scale(0); */
    /* opacity: 0; */
  }
  &:hover .cssaddpointbutton {
    transform: scale(1) !important;
    opacity: 1 !important;
  }
  position: absolute;
  top: 0;
  height: 44px;
  width: 44px;
  z-index: 1;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  margin-left: -22px;
  left: ${({ left }) => `${left}%`};
`;



export const CSSscrubberStyle = styled.div`

  &:hover .cssaddpointbutton {
    transform: scale(0.6);
    opacity: 0.3;
  }

  /* cursor: pointer; */
  & .bar__progress {
    background-color: ${({ color }) => color} !important;
    opacity: 0.6;
  }
  & .bar {
    height: 24px !important;
    background-color: transparent;
    top: 0 !important;
    left: 0;
    transform: translateY(0) !important;
  }
  & .bar {
    /* transform: translateY(-24px) !important; */
  }

  & .bar::after {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: ${({ color }) => color} !important;
    opacity: 0.2;
  }
  & .bar__thumb {
    background-color: ${({ color }) => color} !important;
  }
  & .hover .bar__thumb {
    width: 16px;
    height: 32px;
    transition: transform(100ms);
    z-index: 1;
    background: ${({ color }) => color} !important;
  }

  /* & .scrubber.hover .bar__marker {
    width: 4px;
    height: 24px;
    z-index: 2;
    opacity: 0.3;
    background-color: ${({ color }) => color} !important;
  } */

  & .scrubber.horizontal .bar__marker {
    opacity: 0.7;
    width: 4px;
    height: 24px;
    z-index: 2;
    background-color: ${({ color }) => color} !important;
    display: flex;
    justify-content: center;
  }
  & .bar__marker::after {
    content: "";
    opacity: 0;
    position: absolute;
    z-index: 2;
    width: 24px;
    height: 24px;
    background-color: ${({ color }) => color} !important;
    cursor: pointer;
    transition: transform 150ms ease, opacity 200ms ease;
  }
  & .bar__marker:hover::after {
    content: "";
    opacity: 0.4;
  }
`;
