import React, { Fragment, useEffect, useState, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import styled, { css } from "styled-components";
import { DatePicker } from "./DatePicker";
import { MenuCalendar } from "./MenuCalendar";
import { getImage } from "./utils";
import { UIselect } from "../uikit/UIselect";
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import uniqid from "uniqid";
import {useData} from "../contexts/data";

function DayFiltersInner({ history }) {
  const dataContext = useData();

  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);


  const [shown, setShown] = useState(true)

  useEffect(() => {
    if (!shown) {
      setShown(true)
    }
  }, [shown])

  const getProjectData = (dataType = "projects", oneProjectId, field) => {
    let data = dataContext.data[dataType].filter(
      (one) => one["uniq"] === oneProjectId
    )[0];
    return !field && data ? data : data && data[field] ? data[field] : '';
  };

  const selectColors = {
    idle: `#f7f7f7`,
    // idle: `#FFF`,
    accent: `var(--main-accent-color)`,
    radius: "4px",
  };

  const codeFilterToHash = (filtersArray) => {
    let hash = `${filtersArray.map((filterName, i) => {
      if (i === filtersArray.length - 1) {
        return filterName;
      } else {
      }
      return `${filterName}`;
    })}`;
    return hash;
  };

  const drawMultiselectColors = (filterType, array, img = true, color) => {
    return array.map((oneId) => (

      <div
        key={`option_${oneId}_${uniqid()}`}
        className="x_project_ava x_project_ava--mini">
        <div
          // src="https://assets.website-files.com/61785133118e574036320746/617ba6f68653f362ba29a9ba_Screen%20Shot%202021-10-29%20at%2012.46.34%20AM.png"
          className="x_project_ava_img"
          style={{
            backgroundImage: img
              ? `url(${getImage(getProjectData(filterType, oneId, "img"))})`
              : "",
            backgroundColor: color
              ? getProjectData(filterType, oneId, "color")
              : "",
          }}
        />
      </div>


    ));
  };

  const handleFilter = (val, filterType = "users") => {
    let filter = [];
    if (filterType === "projects") filter = dataContext.state["activeProjects"];
    if (filterType === "users") filter = dataContext.state["activeUsers"];

    let temporaryFilters = [...JSON.parse(JSON.stringify(filter))];
    if (!temporaryFilters.filter((oneEl) => oneEl === val).length) {
      temporaryFilters = [...filter, val];
    } else {
      temporaryFilters = [...temporaryFilters.filter((oneEl) => oneEl !== val)];
    }

    if (!temporaryFilters.length) {
      resetFilter("color");
    } else {
      if (filterType === "projects")
        dataContext.handles.setActiveProjects(temporaryFilters);
      if (filterType === "users")
        dataContext.handles.setActiveUsers(temporaryFilters);

      history.push({
        // hash: `${'users'}:${codeFilterToHash(temporaryFilters, users)}`,
        hash: `u:${codeFilterToHash(temporaryFilters)}`,
      });
    }
  };

  const resetFilter = (filterType = "color") => {
    setProjects([]);
    // history.push({ hash: `` });
  };


  return (
    <>

      <div className={`l_filterbar ${shown ? 'active' : ''}`}>


        <div className="l_filter_section">
          <div className="x_filters_title">Dates</div>
          <DatePicker />
          {/* <MenuCalendar /> */}
          {/* <DateRangePicker/> */}
        </div>
        <div className="l_filter_section">
          <div className="x_filters_title">People</div>

          <UIselect
            dropStyle={{
              display: "flex",
              flexDirection: "column",
              // flexDirection: "row",
              // flexWrap: "wrap",
              // width: 360,
              justifySelf: "stretch",
              borderRadius: "0.6em",
              padding: "0.4em 0.6em",
            }}
            dropItemStyle={{
              borderRadius: "0.4em",
              padding: "0.4em 1em",
              margin: "0.2em",
              minHeight: 32,
            }}
            colors={{ ...selectColors }}
            // handleReset={
            //   Boolean(filter.length) ? () => resetFilter("color") : false
            // }
            multiSelect={true}
            width={220}
            // size="sm"
            placeholder="All teams"
            // active = {filter.length ? filter[0] : null }
            active={
              dataContext.state["activeUsers"].length
                ? dataContext.state["activeUsers"]
                : ""
            }
            activeCustom={
              dataContext.state["activeUsers"].length
                ? drawMultiselectColors(
                  "users",
                  dataContext.state["activeUsers"],
                  true,
                  false
                )
                : null
            }
            menuHandler={(val) => {
              // console.log("history = ", history);
              // console.log("match = ", match);
              // console.log("location = ", location);
              handleFilter(val, "users");
            }}
            options={[
              ...dataContext["data"]["users"].map((user) => {
                return {
                  // title: <>{tag["title"]} - {filterByCategory(tag['items'], category).length}</>,
                  title: <>{user["name"]} {user["nameLast"]}</>,
                  // icon: <MIcon size={18}>add</MIcon>,
                  icon: (
                    <div
                      className="x_project_ava_img"
                      style={{
                        // backgroundColor: user["color"], margin: 0,
                        backgroundImage: `url(${getImage(user["img"], "small")})`,
                      }}
                    >
                      {/* {project["icon"]} */}
                    </div>
                  ),
                  value: user["uniq"],
                  // separated: true,
                };
              }),
            ]}
          />
        </div>
        <div className="l_filter_section">
          <div className="x_filters_title">Projects</div>

          <UIselect
            dropStyle={{
              display: "flex",
              flexDirection: "column",
              // flexDirection: "row",
              // flexWrap: "wrap",
              // width: 360,
              justifySelf: "stretch",
              borderRadius: "0.6em",
              padding: "0.4em 0.6em",
            }}
            dropItemStyle={{
              borderRadius: "0.4em",
              padding: "0.4em 1em",
              margin: "0.2em",
              minHeight: 32,
            }}
            colors={{ ...selectColors }}
            // handleReset={
            //   Boolean(filter.length) ? () => resetFilter("color") : false
            // }
            multiSelect={true}
            width={220}
            // size="sm"
            placeholder="All colors"
            // active = {filter.length ? filter[0] : null }
            active={
              dataContext.state["activeProjects"].length
                ? dataContext.state["activeProjects"]
                : ""
            }
            activeCustom={
              dataContext.state["activeProjects"].length
                ? drawMultiselectColors(
                  "projects",
                  dataContext.state["activeProjects"],
                  true,
                  true
                )
                : null
            }
            menuHandler={(val) => {
              // console.log("history = ", history);
              // console.log("match = ", match);
              // console.log("location = ", location);
              handleFilter(val, "projects");
            }}
            options={[
              ...dataContext["data"]["projects"].map((project) => {
                return {
                  // title: <>{tag["title"]} - {filterByCategory(tag['items'], category).length}</>,
                  title: <>{project["title"]}</>,
                  // icon: <MIcon size={18}>add</MIcon>,
                  icon: (
                    <div
                      className="x_project_ava_img"
                      style={{
                        backgroundColor: project["color"],
                        margin: 0,
                        backgroundImage: `url(${getImage(project["img"])})`,
                      }}
                    >
                      {/* {project["icon"]} */}
                    </div>
                  ),
                  value: project["uniq"],
                  // separated: true,
                };
              }),
            ]}
          />

          {/* <div className="c_m_ul">
                      <div className="c_m_li">
                        <div className="c_m_check">
                          <div className="x_color_teal">check_box</div>
                        </div>
                        <div className="c_m_check_label">
                          <div>RC Racer 2021</div>
                        </div>
                      </div>
                      <div className="c_m_li">
                        <div className="c_m_check">
                          <div>check_box_outline_blank</div>
                        </div>
                        <div className="c_m_check_label">
                          <div>Industrial Design Org</div>
                        </div>
                      </div>
                      <div className="c_m_li">
                        <div className="c_m_check">
                          <div className="x_color_blue">check_box</div>
                        </div>
                        <div className="c_m_check_label">
                          <div>2021 US&nbsp;Toy Dev</div>
                        </div>
                      </div>
                      <div className="c_m_li">
                        <div className="c_m_check">
                          <div>check_box_outline_blank</div>
                        </div>
                        <div className="c_m_check_label">
                          <div>Global Ops</div>
                        </div>
                      </div>
                      <div className="c_m_li">
                        <div className="c_m_check">
                          <div className="x_color_blue2">check_box</div>
                        </div>
                        <div className="c_m_check_label">
                          <div>Malaysia Supply</div>
                        </div>
                      </div>
                    </div> */}
        </div>

        <div className="l_filter_section">
          <div className="x_filters_title">Apps</div>

          <UIselect
            dropStyle={{
              display: "flex",
              flexDirection: "column",
              // flexDirection: "row",
              // flexWrap: "wrap",
              // width: 360,
              justifySelf: "stretch",
              borderRadius: "0.6em",
              padding: "0.4em 0.6em",
            }}
            dropItemStyle={{
              borderRadius: "0.4em",
              padding: "0.4em 1em",
              margin: "0.2em",
              minHeight: 32,
            }}
            colors={{ ...selectColors }}
            // handleReset={
            //   Boolean(filter.length) ? () => resetFilter("color") : false
            // }
            multiSelect={true}
            width={220}
            // size="sm"
            placeholder="All apps"
            // active = {filter.length ? filter[0] : null }
            active={
              dataContext.state["activeApps"].length
                ? dataContext.state["activeApps"]
                : ""
            }
            activeCustom={
              dataContext.state["activeApps"].length
                ? drawMultiselectColors(
                  "apps",
                  dataContext.state["activeApps"],
                  true,
                  false
                )
                : null
            }
            menuHandler={(val) => {
              // console.log("history = ", history);
              // console.log("match = ", match);
              // console.log("location = ", location);
              handleFilter(val, "apps");
            }}
            options={[
              ...dataContext["data"]["apps"].map((user) => {
                return {
                  // title: <>{tag["title"]} - {filterByCategory(tag['items'], category).length}</>,
                  title: <>{user["name"]} {user["nameLast"]}</>,
                  // icon: <MIcon size={18}>add</MIcon>,
                  icon: (
                    <div
                      className="x_project_ava_img"
                      style={{
                        // backgroundColor: user["color"], margin: 0,
                        backgroundImage: `url(${getImage(user["img"], "small")})`,
                      }}
                    >
                      {/* {project["icon"]} */}
                    </div>
                  ),
                  value: user["uniq"],
                  // separated: true,
                };
              }),
            ]}
          />
        </div>

      </div>
    </>
  );
}

export const DayFilters = withRouter(DayFiltersInner);
