import React, {
  Component,
  useContext,
  useState,
  useEffect,
  useRef,
  Fragment
} from 'react';
import styled, { css } from 'styled-components';
import uniqid from "uniqid";


// import Tap from "react-interactions";
import './Uikit.css';
import { MIcon } from './Uikit';

export const getBoundingClientRect = (element) => {
  const rect = element.getBoundingClientRect();
  return {
    top: rect.top,
    right: rect.right,
    bottom: rect.bottom,
    left: rect.left,
    width: rect.width,
    height: rect.height,
    x: rect.x,
    y: rect.y
  };
};

export const CssLoginPop0 = styled.div`
  @media (max-width: 991px) {
  }

  @media (max-width: 767px) {
  }

  @media (max-width: 479px) {
  }
`;

let debug = true;
// let debug = false;

export function UIselect({
  direction,
  options = [{ title: '–', value: 'none' }],
  multiSelect,
  onHover = false,
  size,
  darkenBackground = false,
  active,
  customTrigger,
  menuHandler,
  handleReset,
  placeholder = 'Select',
  separated,
  extra,
  help,
  dropIcon = true,
  width,
  dropLeft,
  theme = {
    idle: 'transparent',
    bg: 'white',
    accent: '#157afb',
    text: '#00244d',
    separator: {
      color: '#ccc'
    }
  },
  dropStyle = {},
  dropItemStyle = {},
  itemCustom,
  itemActiveCustom,
}) {
  const drop = useRef(null);
  const [pos, setPos] = useState(null);

  const [height, setHeight] = useState(null);

  useEffect(() => {
    const windowHeight = window.innerHeight;
    if (!direction && pos && drop.current) {
      const dropPos = getBoundingClientRect(drop.current);
      setHeight(windowHeight / 2 - 200);
      if (windowHeight < pos.bottom + dropPos.height) {
        setToTop(true);
      } else {
        setToTop(false);
      }
    }
  }, [pos]);

  const [toTop, setToTop] = useState(direction);

  const [shown, setShown] = useState(false);

  const selectInput = useRef()
  const [inputWidth, setInputWidth] = useState(100);

  useEffect(() => {
    setInputWidth(selectInput.current.offsetWidth)
  }, [selectInput])




  const drawMultiselect = (options, active) => {

    let filteredOptions = options.filter(one => active.includes(one['value']))

    return filteredOptions.map((one) => {
      if (!itemActiveCustom) {
        return (<span key={uniqid()}>
          {one['title']}
        </span>)
      } else {
        debugger
        return itemActiveCustom(one)
      }


    });
  };





  return (
    <>
      {shown && darkenBackground && <CssZ theme={theme} width={240} />}
      <div style={{
        width: width ? `${width}px` : 'auto', minWidth: 44, width: '100%',
        fontSize: theme['fontSize'] ? theme['fontSize'] : 14
      }}>

        {customTrigger}

        <CssSelect
          customTrigger={customTrigger ? true : false}
          accent={theme['accent']}
          bg={theme['bg']}
          text={theme['text']}
          idle={theme['idle']}
          radius={theme['radius']}
          size={size}
          className="xsb_but_link_input"
          style={{
            width: width ? `${width}px` : 'auto',
            pointerEvents: menuHandler ? 'inherit' : 'none',
          }}
          shown={shown}
          onClick={(e) => {
            if (!shown) {
              setShown(true);
              setPos(getBoundingClientRect(e.target));
            }
          }}
        >

          {!menuHandler && (
            <div className="xsb_but_seturl_placeholder">
              <MIcon size={18}>
                lock
              </MIcon>
            </div>
          )}

          {menuHandler && (<>


            <CssDrop
              customTrigger={customTrigger ? true : false}
              size={size}
              ref={drop}
              className={`${customTrigger ? ''
                : toTop
                  ? 'xsb_select_drop0 xsb_select_drop0--top'
                  : 'xsb_select_drop0'
                }`}
              // shown={shown}
              onMouseLeave={() => {
                !debug && setShown(false);
              }}
            >




              {!customTrigger && <CssSelectPlaceholder
                ref={selectInput}
                accent={theme['accent']}
                radius={theme['radius']}
                bg={theme['bg']}
                text={theme['text']}

                size={size}
                toTop={toTop}
                shown={shown}
                onClick={() => {
                  if (shown) setShown(false);
                }}
              >
                {placeholder && (!active || !active.length) && (
                  <div className="xsb_but_seturl_placeholder"
                    style={{
                      whiteSpace: 'nowrap',
                      paddingRight: dropIcon ? '16px' : '8px'
                    }}
                  >{placeholder}</div>
                )}

                {!multiSelect && typeof active !== 'undefined' && (
                  <div className="xsb_but_seturl_placeholder">
                    {!Boolean(itemActiveCustom) && options.map((item) => {
                      const ret = active === item.value ? item.title : '';
                      return ret || null;
                    })}
                    {Boolean(itemActiveCustom) && itemActiveCustom(options.filter(one => one['value'] === active)[0])}
                  </div>
                )}

                {multiSelect && typeof active !== 'undefined' && Boolean(active.length) && (
                  <div style={{
                    // overflow:'hidden',
                    width: '100%'
                  }}>
                    <div className="xsb_but_seturl_placeholder">
                      {drawMultiselect(options, active)}
                    </div>
                  </div>
                )}

                {dropIcon && <MIcon size={14} left>
                  <div>expand_more</div>
                </MIcon>}
                &nbsp;
              </CssSelectPlaceholder>}

              <CssSelectB
                radius={theme['radius']}
                shown={shown}
                className="xsb_select_drop"
                style={{
                  minWidth: `${inputWidth}px`,
                  boxShadow: '0 3px 22px 4px rgba(64, 69, 74, 0.31)',
                  bottom: toTop ? '38px' : 'auto',
                  right: dropLeft ? '0' : 'auto',
                  position: 'absolute',
                  width: width ? 'auto' : '100%',
                  justifySelf: 'stretch',
                  maxHeight: 400,
                  overflow: 'auto',

                  ...dropStyle
                }}
              >
                {/* <CssMenuLi
                  className="xsb_select_drop_li"
                  key={`preset_0`}
                  onClick={(e)=>{
                    e.preventDefault();
                    setShown(false);
                  }}
                >
              </CssMenuLi> */}


                {options.map((item, ind) => (
                  <CssMenuLi
                    separated={item['separated']}
                    theme={theme}
                    active={active && multiSelect ? active.includes(item.value) : active === item.value}
                    className="xsb_select_drop_li"
                    key={`preset_${item.value}_${uniqid()}`}
                    onMouseEnter={(e) => {
                      e.preventDefault();
                      shown && onHover && menuHandler(item.value);
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      !item['handler'] && menuHandler(item.value);
                      item['handler'] && item['handler'](item.value);
                      if (!multiSelect) setShown(false);
                    }}
                    style={{ ...dropItemStyle }}
                  >
                    {Boolean(item['icon']) && <CSSicon>{item['icon']}&nbsp;&nbsp;</CSSicon>}
                    {!Boolean(itemCustom) && item.title}
                    {Boolean(itemCustom) && itemCustom(item, multiSelect ? active.includes(item.value) : active === item.value)}
                  </CssMenuLi>
                ))}

                {!!handleReset && (
                  <CssMenuLi
                    theme={theme}
                    className="xsb_select_drop_li"
                    key={`preset_reset${uniqid()}`}
                    onClick={(e) => {
                      e.preventDefault();
                      handleReset();
                      setShown(false);
                    }}
                  >
                    <div className="xx_but_menu_icon xx_but_sett_icon--blue">
                      backspace
                    </div>
                    {' '}
                    Reset
                  </CssMenuLi>
                )}

                {typeof extra !== 'undefined' && (
                  <CssMenuLi
                    accent={theme['accent']}
                    theme={theme}
                    bg={theme['bg']}
                    text={theme['text']}

                    className="xsb_select_drop_li"
                    key={`preset_custom_${uniqid()}`}
                    onMouseEnter={(e) => {
                      e.preventDefault();
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    {extra}
                  </CssMenuLi>
                )}


                {help}
              </CssSelectB>
            </CssDrop>
          </>
          )}

        </CssSelect>

      </div>
    </>
  );
}

export const CssSelectUl = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
`;

export const CssSelectB = styled.div`
  transition: all 150ms ease-in-out;
  transform: translateY(-8px);
  padding: 0;
  opacity: 0;
  border-radius: ${({ radius }) => radius ? radius : '3px'};
  /* z-index: 0; */
  ${({ shown }) => shown
    && css`
       {
        transform: translateY(0px);
        opacity: 1;
        /* z-index: 1; */
      }
    `};

  /* &:not(:hover) div {
    background-color: inherit;
    color: blue;
  } */
`;

export const CssDrop = styled.div`
  min-height: ${({ size }) => (size === 'sm' ? '36px' : '44px')};
  height: ${({ size }) => (size === 'sm' ? '36px' : '44px')};
  padding-top: ${({ size, customTrigger }) => (customTrigger ? '' : size === 'sm' ? '40px' : '46px')};
`;

export const CssSelectPlaceholder = styled.div`
  cursor: pointer;
  position: absolute;
  overflow: hidden;
  left: 0%;
  right: 0%;
  top: 0px;
  bottom: auto;
  z-index: 10000;
  min-height: ${({ size }) => (size === 'sm' ? '36px' : '44px')};
  padding-top: 0px;
  display: flex;
  align-items: center;
  border-radius: ${({ radius }) => radius ? radius : '3px'};


  ${({ toTop }) => toTop
    && css`
       {
        bottom: 0px;
        top: auto;
      }
    `};

  /* ${({ shown }) => shown
    && css`
       {
        background-color: ${({ bg }) => bg ? bg : 'transparent'};
        box-shadow: inset 0 0 0 2px ${({ accent }) => accent ? accent : '#157afb'};
        color: ${({ text }) => text ? text : '#00244d'};
      }
    `};

    &:hover {
        background-color: ${({ bg }) => bg ? bg : '#ffffff'};
        box-shadow: inset 0 0 0 2px ${({ accent }) => accent ? accent : '#157afb'};
        color: ${({ text }) => text ? text : '#00244d'};
    } */

  /* &:not(:hover) div {
    background-color: inherit;
    color: blue;
  } */

  .xx_set_sect & {
    opacity: 0.8;
  }
  .xx_set_sect:hover & {
    opacity: 1;
  }
`;

export const CssSelect = styled.div`
  background-color: ${({ idle }) => idle ? idle : 'transparent'};

  min-height: ${({ size }) => (size === 'sm' ? '36px' : '44px')};
  height: ${({ size }) => (size === 'sm' ? '36px' : '44px')};
  overflow: ${({ shown }) => (shown ? 'visible' : 'hidden')};;
  z-index: 0;
  border-radius: ${({ radius }) => radius ? radius : '3px'};
  ${({ shown }) => shown
    && css`
       {
        z-index: 1;
      }
    `};
    &:hover {
        background-color: ${({ bg }) => bg ? bg : '#ffffff'};
        box-shadow: inset 0 0 0 2px ${({ customTrigger, accent }) => customTrigger ? '' : accent ? accent : '#157afb'};
        color: ${({ text }) => text ? text : '#00244d'};
    }
`;




export const CssMenuLi = styled.div`
  color: ${({ active, accent }) => (active ? `${({ accent }) => accent ? accent : '#157afb'}` : 'inherit')};
  /* font-weight: ${({ active }) => (active ? 'bolder' : 'normal')}; */
  display: flex;
  align-items: center;
  background-color: ${({ active }) => (active ? 'rgba(150, 203, 255, 0.19)' : 'transparent')};
  position: relative;
  &:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    right:0;
    top: -0.2em;
    border-top: ${({ separated, theme }) => (separated ? `1px solid ${theme['separator'] ? theme.separator['color'] : '#ccc'}` : 'none')};
  };
`;

const CssZ = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  z-index: -1;
  background: rgba(0, 0, 0, 0.56);
`;


const CSSicon = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
`