import React from "react";


export function LoginLayout({ children }) {
  return (<>


    <div className="l_page0">
      <div className="l_page_toppatternzz">
        <div className="l_page_toppattern_dark" />
      </div>
      <div className="l_page_0">
        <div className="l_page_w">
          <div className="l_page_b l_page_b--lg l_page_b--lp">
            <div className="l_page_c l_page_c--w">
              <div className="padding padding--sm">
                <div className="line line--lg center">
                  <div className="x_lp_logo0">
                    <div className="x_h_logo x_h_logo_login" />
                  </div>
                  <h1 className="x_lp_slogan1">
                    <strong>Welcome to General&nbsp;Symbol</strong>
                  </h1>
                  <h1 className="x_lp_slogan_sub">Knowledge Made Visible</h1>
                </div>
                <div className="line sm center">
                  <div className="x_lp_login_form0">


                    {children}


                  </div>
                </div>
                <div className="line sm center">
                  <div className="x_lp_login_form0 x_lp_login_form0--note">
                    <div className="line">
                      <div>General Symbol is in the Soft Launch phase of development. Please address your suggestions to <a href="mailto:support@generalsymbol.com" className="link">support@generalsymbol.com</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="line sm center">
                  <div className="padding padding--sm">
                    <div className="x_lp_padding">
                      <div className="footer-flex-container">
                        <div className="x_f_logo">
                          <div className="x_f_logo_img">
                            <div className="m_logo0">
                              <div className="x_logo_1">
                                <div className="x_h_logo x_h_logo_footer" />
                              </div>
                              <h1 className="x_f_logotype">
                                <strong>General&nbsp;Symbol</strong>
                              </h1>
                            </div>
                          </div>
                          <div className="x_f_copy">559 Everett Ave, Palo Alto <br />CA, 94301</div>
                          <div className="x_f_cols_col x_f_cols_col--mobsoc">
                            <div className="x_f_section">
                              <div className="l_flex l_flex--middle">
                                <div className="x_f_icon w-embed">
                                  <svg width={17} height={17} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.49902 0C3.80459 0 -0.000976562 3.80557 -0.000976562 8.5C-0.000976562 13.1944 3.80459 17 8.49902 17C13.1935 17 16.999 13.1944 16.999 8.5C16.999 3.80557 13.1935 0 8.49902 0Z" fill="white" />
                                    <path d="M12.9155 6.26316V5.13158L8.5 7.94738L4.08451 5.13158V6.26316L8.5 9.05262L12.9155 6.26316ZM12.9155 4C13.2081 4 13.4577 4.11404 13.6643 4.34211C13.8881 4.57018 14 4.83333 14 5.13158V11.8684C14 12.1667 13.8881 12.4298 13.6643 12.6579C13.4577 12.886 13.2081 13 12.9155 13H4.08451C3.79186 13 3.53365 12.886 3.30986 12.6579C3.10329 12.4298 3 12.1667 3 11.8684V5.13158C3 4.83333 3.10329 4.57018 3.30986 4.34211C3.53365 4.11404 3.79186 4 4.08451 4H12.9155Z" fill="black" />
                                  </svg>
                                </div>
                                <div>
                                  <a href="#" className="x_f_title x_f_title--a">info@circlegain.com</a>
                                </div>
                              </div>
                            </div>
                            <div className="x_f_section">
                              <div className="l_flex l_flex--middle">
                                <div className="x_f_icon w-embed">
                                  <svg width={17} height={17} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.5 0C3.80557 0 0 3.80557 0 8.5C0 13.1944 3.80557 17 8.5 17C13.1944 17 17 13.1944 17 8.5C17 3.80557 13.1944 0 8.5 0ZM13.64 6.23322C13.644 6.33357 13.6461 6.43409 13.6461 6.53513C13.6461 10.1661 10.8831 13.9217 6.25948 13.9217C4.84609 13.9217 3.46991 13.5184 2.27983 12.7551C2.25496 12.7393 2.2447 12.7085 2.25461 12.6809C2.26452 12.6534 2.29252 12.6363 2.32122 12.6397C2.52087 12.6635 2.72504 12.6753 2.92765 12.6753C4.0273 12.6753 5.06835 12.3353 5.94887 11.6903C4.89339 11.6012 3.98626 10.887 3.66 9.868C3.65322 9.84678 3.65809 9.82365 3.6727 9.80696C3.68748 9.79026 3.71009 9.78296 3.73165 9.78696C4.02417 9.84244 4.32157 9.84609 4.60817 9.79948C3.51965 9.46122 2.75461 8.44157 2.75461 7.2807L2.75496 7.24696C2.75548 7.22487 2.76748 7.2047 2.78661 7.19374C2.80557 7.18261 2.82922 7.18243 2.84835 7.19322C3.13217 7.35078 3.44626 7.452 3.76852 7.49078C3.14296 6.99217 2.77565 6.23635 2.77565 5.42783C2.77565 4.96139 2.89913 4.50296 3.13287 4.10209C3.1433 4.08417 3.16174 4.0727 3.18226 4.07113C3.20296 4.06904 3.22296 4.07809 3.236 4.09409C4.51635 5.66452 6.39965 6.63148 8.41357 6.75583C8.38 6.58678 8.36296 6.41252 8.36296 6.2367C8.36296 4.78261 9.54591 3.59983 10.9998 3.59983C11.7146 3.59983 12.4054 3.89357 12.9005 4.40696C13.4586 4.29322 13.9903 4.088 14.4814 3.79687C14.5038 3.78348 14.532 3.78557 14.5527 3.80174C14.5729 3.81809 14.5812 3.84522 14.5732 3.87026C14.4068 4.39078 14.0823 4.84487 13.6463 5.1727C14.0569 5.10122 14.4553 4.98052 14.8343 4.81235C14.8597 4.8007 14.8897 4.808 14.908 4.82939C14.9261 4.85096 14.9277 4.88174 14.912 4.90504C14.5668 5.42174 14.139 5.86852 13.64 6.23322Z" fill="white" />
                                  </svg>
                                </div>
                                <div>
                                  <a href="#" className="x_f_title x_f_title--a">circle_gain</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="x_f_cols0">
                          <div className="x_f_cols_col">
                            <div className="x_f_section">
                              <ul role="list" className="x_f_ul w-list-unstyled">
                                <li className="x_f_li">
                                  <a href="#" className="x_f_a">Product tour</a>
                                </li>
                                <li className="x_f_li">
                                  <a href="#" className="x_f_a">Pricing</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="x_f_cols_col">
                            <div className="x_f_section">
                              <ul role="list" className="x_f_ul w-list-unstyled">
                                <li className="x_f_li">
                                  <a href="#" className="x_f_a">Team</a>
                                </li>
                                <li className="x_f_li">
                                  <a href="#" className="x_f_a">Careers</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="x_f_cols_col">
                            <div className="x_f_section">
                              <ul role="list" className="x_f_ul w-list-unstyled">
                                <li className="x_f_li">
                                  <a href="#" className="x_f_a">Terms &amp; Conditions<br />
                                  </a>
                                </li>
                                <li className="x_f_li">
                                  <a href="#" className="x_f_a">Privacy policy</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="x_f_cols_col">
                            <div className="x_f_section x_f_section--mobsoc">
                              <div className="l_flex l_flex--middle">
                                <div>
                                  <a href="mailto:info@circlegain.com" className="x_f_title x_f_title--a">joe@generalsymbol.com</a>
                                </div>
                              </div>
                            </div>
                            <div className="x_f_section x_f_section--mobsoc">
                              <div className="l_flex l_flex--middle">
                                <div>
                                  <a href="https://twitter.com/circle_gain" target="_blank" className="x_f_title x_f_title--a">@generalsymbol</a>
                                </div>
                              </div>
                            </div>
                            <div className="x_f_section">
                              <ul role="list" className="x_f_ul w-list-unstyled" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="x_lp_login_bg" />
        </div>
      </div>
    </div>
  </>);
}
;
