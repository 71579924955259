import React, {forwardRef, useImperativeHandle} from "react";

import PropTypes from "prop-types";

import {MP4_SOURCE_TYPE, PLAYER_SETTINGS} from "../constants";
import "../plugins";
import Video from "./Video";

const MimeTypeMPEGDASH = "application/dash+xml";

const GsPlayer = forwardRef((props, ref) => {
  const playerRef = React.useRef(null);
  const initialSource = props.source; //getInitialSource(props.source);

  useImperativeHandle(ref, () => ({
    getPlayer: () => {
      return playerRef.current;
    },
  }));

  const VideoOptions = {
    ...PLAYER_SETTINGS,
    ...props.settings,

    sources: [
      {
        src: initialSource,
        type: MP4_SOURCE_TYPE,
      },
      // {
      //   src: initialSource,
      //   type: MimeTypeMPEGDASH,
      // }
    ],
  };

  const initializeCustomPlugins = (player) => {
    playerRef.current = player;
  

    player.speedSelector();
    player.qualitySelector();

    props.onReady && props.onReady();
  };

  return (
    <>
      <Video options={VideoOptions} onReady={initializeCustomPlugins} />
    </>
  );
});

GsPlayer.propTypes = {
  source: PropTypes.string.isRequired,
  settings: PropTypes.object,
  onReady: PropTypes.func,
};

GsPlayer.defaultProps = {
  onReady: null,
};

export default GsPlayer;
