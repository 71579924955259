import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { LayoutWithHeader } from "../components/LayoutWithHeader";
import { useAuth } from "../contexts/auth";
import { UIinput } from "../uikit/UIinput";
import { UIbutton } from "../uikit/Uikit";

const UsersInner = () => {
  const authContext = useAuth()
  return (
    <>
      <LayoutWithHeader title={"Users & Access"}>
        <SettingsUsers />
      </LayoutWithHeader>
    </>
  )
}

export function SettingsUsers(props) {
  const [addUser, setAddUser] = useState(false);


  let invited = [
    {
      uniq: 'danny',
      email: 'danny@generalsymbol.com',
    },
    {
      uniq: 'artem',
      email: 'artem@generalsymbol.com',
    },
    {
      uniq: 'steph',
      email: 'steph@generalsymbol.com',
    },
  ]

  let users = [
    {
      uniq: 'joe',
      email: 'joe@generalsymbol.com',
    },
    {
      uniq: 'misha',
      email: 'misha@generalsymbol.com',
    },
  ]



  const handleAddUser = () => {
    //TODO: create a <handle>
    setAddUser(!addUser)
  }

  return (
    <>


      <div className="l_page_b l_page_b--bg">
        <div className="l_page_w">
        <div className="padding">
          <div id="projects" className="l_section l_section--padding">
            <div className="line line--lg">
              <div className="flex">
                <div className="x_h_left">
                  <h1>Users &amp; Access</h1>
                </div>
                <div className="x_h_right" />
              </div>
              </div>





            <div className="line">
                <div className="l_pr_container">
                  <div className="line">
                    <h3>Invited users</h3>
                  </div>






                  {invited.map((one, ind) => <div className="l_pr_columns l_pr_columns--table" key={`user_${one['uniq']}`}>
                    <div className="l_pr_col l_pr_col--user">
                      <div className="x_user">
                        {one['email']}
                      </div>
                    </div>
                    <div className="l_pr_col l_pr_col--r">

                    </div>
                    <div className="l_pr_col l_pr_col--r">
                      {/* <div className="t_link" onClick={() => void (0)} >
                        Remove
                      </div> */}
                    </div>
                  </div>)}









                  {!addUser && <div className="line">
                    <div className="x_invite_trigger" onClick={() => setAddUser(!addUser)}>
                      <div>Invite user</div>
                    </div>
                  </div>}




                  {addUser && <div className="line">
                    <div className="l_pr_container l_pr_container--new">

                        <div className="l_pr_columns">

                          <div className="l_pr_col l_pr_col--r">

                              <div className="c_m_section">

                                <div className="l_pr_columns">

                              <div className="l_pr_col">
                                <UIinput
                                  compact
                                  label='Email'
                                  style={{ width: 300 }}
                                  theme={{ background: 'white' }}
                                  placeholder="New user email"
                                />
                              </div>

                              <div className="l_pr_col l_pr_col--r">
                                {/* //TODO: Use TOAST to show errors */}
                                <div className="x_button x_button--main"
                                  onClick={() => {
                                    handleAddUser()
                                  }}
                                >
                                  <div>Send invite</div>
                                  <div className="x_button_icon">
                                    <div>send</div>
                                  </div>
                                </div>



                                </div>
                            </div>


                              </div>

                        </div>
                      </div>



                    </div>
                  </div>}


                </div>
              </div>


            <div className="line">
              <div className="l_pr_container">
                <div className="line">
                  <h3>Active users</h3>
                </div>


                <div className="line">



                    {users.map((one, ind) => <div className="l_pr_columns l_pr_columns--table" key={`user_${one['uniq']}`}>
                    <div className="l_pr_col l_pr_col--user">
                      <div className="x_user">
                          {one['email']}
                      </div>
                    </div>
                    <div className="l_pr_col l_pr_col--r">
                        {one['role']}
                    </div>
                    <div className="l_pr_col l_pr_col--r">
                        {/* Remove */}
                    </div>
                    </div>)}



                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>




    </>
  )
}



export const Users = withRouter(UsersInner);