import React from "react";
import {MenuMain} from "./MenuMain";
import {Player} from "./Player";
import {getDataById} from '../utils';
import "react-perfect-scrollbar/dist/css/styles.css";
import {PlaylistAddButton} from "./PlaylistAddButton";
import {useData} from "../contexts/data";

export function Layout({playlistId, videoId, children}) {
	const dataContext = useData();
	// videoId = videoId || getDataById(dataContext, 'playlists', playlistId, 'videos')
	videoId = ''
	return (<>
		<div className="b0">
      <div className="l_bg"></div>
			<div className="l_c">
        <MenuMain space={"company"} />
				<div className="l_c_inner">
					{/* <div className={`l_catalog ${Boolean(videoId) ? 'l_catalog--v' : ''}`}> */}
					<div className={`l_catalog ${Boolean(videoId) ? 'l_catalog--video' : ''}`}>
						<div className={`l_catalog_inner`}>
							<div className="l_playlist">
								{children}
							</div>
						</div>
					</div>
					{Boolean(videoId) && <Player videoID={videoId}/>}
				</div>
			</div>
		</div>
	</>);
}