import React, { useState } from "react";
import { DayFilters } from "./DayFilters";
import { withVersion } from './withVersion';
import { useData } from "../contexts/data";
import { UIbuttonGhost } from "../uikit/UIbuttonGhost";


function PlaylistTopInner({
  playlistId, id, img, title, user, color, emoji, icon, size, filters = true,
  toggle,
  privateList = true,
}) {
  const dataContext = useData();

  const [menu, setMenu] = useState(false);
  const [edit, setEdit] = useState(false);


  const [playlistName, setPlaylistName] = useState(title);


  const playlistRename = dataContext.handles.playlistRename;
  const playlistDelete = dataContext.handles.playlistDelete;


  // const { id, img, title, user, color, emoji, icon } = getDataByUniq(dataContext, "playlists", playlistId) || getDataById(dataContext, "playlists", "default");
  return (
    <>
      {/* <Link to="/list/" style={{ width: "100%" }}> */}
      <div
        // className={`x_cover0 ${size === "sm" ? "x_cover0--sm" : ""}`}
        className={`x_cover0 x_cover0--sm`}
      >
        <div
          className="x_header_pic"
        // style={{
        //   backgroundImage: `linear-gradient(180deg, rgba(6, 161, 154, 0.7), rgba(6, 161, 154, 0.7)), url("${getImage(
        //     img,
        //     "large"
        //   )}")`,
        // }}
        />
        <div className="x_cover_content">
          <div className="x_cover_title0">


            <div className="x_cover_h1">
              <div className="x_cover_emoji">
                {Boolean(emoji) && <>{emoji}</>}
                {Boolean(icon) && <>{icon}</>}
              </div>
              {!edit && <div className="x_cover_title">{playlistName}</div>}

              {edit && <input className="x_cover_title_input" value={playlistName} onChange={(e) => setPlaylistName(e.target.value)} />}


            </div>


            {!edit && (

              <UIbuttonGhost
                className='x_button--hover'
                onClick={(e) => {
                  e.stopPropagation();
                  setEdit(!edit);
                }}
                icon='edit'
              />

            )}


          </div>

          <div className="x_button_container_right">



            <UIbuttonGhost
              title='Private'
              icon='lock'
            />




          </div>

        </div>




        {edit && <div className="x_playlist_actions">
          <div className="x_button_container_left">





            <UIbuttonGhost
              className='x_button--main'
              onClick={(e) => {
                e.stopPropagation();
                setEdit(!edit);
                playlistRename(playlistName)
              }}
              title='Save changes'
              icon='check'
            />





            <UIbuttonGhost
              onClick={(e) => {
                e.stopPropagation();
                setEdit(!edit);
              }}
              title='Cancel'
            />





          </div>

          <div className="x_button_container_right">





            {/* <UIbuttonGhost
  title='Image'
  icon='arrow_drop_down'
/> */}



            <UIbuttonGhost
              onClick={(e) => {
                e.stopPropagation();
                playlistDelete(playlistId);
              }}
              title='Delete playlist'
              icon='delete'
            />


          </div>

        </div>}




      </div>
      {/* </Link> */}


      {/* {menu && <DayFilters />} */}

    </>
  );
}


export const PlaylistTop = withVersion(PlaylistTopInner)




