import React, { Fragment, useEffect, useState, useContext } from 'react'
import { Link, withRouter, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import styled, { css } from 'styled-components';

export function NewSpace(props) {

  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  return (

    <div className="" style={{ width: '100%' }}>
      <h1>New Space</h1>
      <div onClick={() => history.goBack()}>Cancel</div>
    </div>

  )
}