import React from 'react';
import {ENV, GRAPHQL_URL} from "../config";
import {ApolloClient, createHttpLink, InMemoryCache} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";

const APIContext = React.createContext(null)

const useApi = () => React.useContext(APIContext)

const APIProvider = (props) => {
	const httpLink = createHttpLink({
		uri: GRAPHQL_URL,
	});

	const authLink = setContext((_, {headers}) => {
		headers = headers ?? {}
		try {
			let token = localStorage.getItem("token")
			if (token) {
				headers.Authorization = 'Bearer ' + token
			}
		} catch (err) {
			console.log('authLink error', err)
		}
		return {
			headers
		}
	});


	const client = new ApolloClient({
		link: authLink.concat(httpLink), cache: new InMemoryCache(), connectToDevTools: ENV == 'local' || ENV == 'dev'
	});

	const query = (params) => {
		return new Promise((resolve, reject) => {
			client.query(params)
				.then((result) => resolve(result.data))
				.catch((error) => reject(error))
		})
	}

	const mutate = (params) => {
		return new Promise((resolve, reject) => {
			client.mutate(params)
				.then((result) => resolve(result.data))
				.catch((error) => reject(error))
		})
	}

	const reset = () => {
		return new Promise((resolve, reject) => {
			client.resetStore()
				.then((result) => resolve(result))
				.catch((error) => reject(error))
		})
	}

	const context = {
		query,
		mutate,
		reset
	}

	return (<APIContext.Provider value={{...context}} {...props}/>)
}

export {useApi, APIProvider}