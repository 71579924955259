import React from "react";

export const formatParamsToDate = (match) => {
	return `${match['params']['month']}/${match['params']['day']}/${match['params']['year']}`
}

export const GetDateFormat = (date) => {
	var month = (date.getMonth() + 1).toString();
	month = month.length > 1 ? month : '0' + month;
	var day = date.getDate().toString();
	day = day.length > 1 ? day : '0' + day;
	// return month + '/' + day + '/' + date.getFullYear();
	return date.getFullYear() + '/' + month + '/' + day;
}

export const dateObj = new Date();
export const dateFormat = GetDateFormat(dateObj);

export const minToHours = (min) => {
	let hrs = min / 60;
	hrs = (hrs > 1) ? parseInt(hrs.toString().split('.')[0]) : 0;
	let minutes = min - (hrs * 60);
	if (minutes.toString().length === 1) minutes = '0' + minutes.toString();
	return hrs + ':' + minutes;
}

export let getDataById = (ctx, modelName, id) => {
	return [];
};

export let getDataByUniq = (ctx, modelName, model, fieldName) => {
	return [];
};

export let getMetadataCategories = (ctx, id, sort) => {
	return [];
};

export let getImage = (id) => {
	return {};
};
