import videojs from "video.js";

import {SPEED_OPTIONS} from "../../constants";
import SpeedSelectorItem from "./SpeedSelectorItem";

const PlaybackRateMenuButton = videojs.getComponent("PlaybackRateMenuButton");

class SpeedSelectorMenu extends PlaybackRateMenuButton {
  constructor(player, options) {
    super(player, options);

    this.on(player, "updateSpeed", (e) => {
      this.updateLabel(e);
    });

    this.addClass("vjs-icon-speed");
  }

  triggerItemUpdate() {
    this.menuItems.forEach((item) => item.update());
  }

  createItems() {
    this.menuItems = [];

    for (const option of SPEED_OPTIONS) {
      this.menuItems.push(
        new SpeedSelectorItem(this.player_, {
          controller: this,
          label: option.label,
          factor: option.factor,
        })
      );
    }

    return this.menuItems;
  }

  playbackRateSupported() {
    return this.player().tech_ && this.player().tech_.featuresPlaybackRate;
  }

  updateLabel() {
    if (this.playbackRateSupported()) {
      this.labelEl_.textContent = `${this.player().displaySpeed}x`;
    }
  }

  handleClick() {
    if (this.buttonPressed_) {
      this.unpressButton();
    } else {
      this.pressButton();
    }
  }
}

export default SpeedSelectorMenu;
