import React, {useEffect, useRef, useState,} from "react";
import {withRouter} from "react-router-dom";
import styled from "styled-components";
import {
	getDataById, getImage, getMetadataCategories,
	minToHours,
} from "../utils";
import {HoverScrubber} from "./Scrubber";
import {API_URL, SCREEN_RATIO} from "../config";
import {PlaylistTop} from "./PlaylistTop";

import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import {UserBadge} from './UserBadge';
import {ContentList} from './ContentList';
import {useData} from "../contexts/data";

export function PlaylistInner({history, match, playlistId}) {
	const [expanded, setExpanded] = useState(true);
	const dataContext = useData();

	return (
		<>
			{/* <div
      className={`l_playlist ${expanded ? "l_playlist--md" : "l_playlist--"}`}
    > */}
			{/* <div className="h0">
        <div
          className="ui_tabs ui_tabs--expandable"
          onClick={() => setExpanded(!expanded)}
        >
          <div className="ui_tabs__ul">
            <div className="ui_tabs__li">
              <div className="ui_tabs__menu_item ui_tabs__menu_item--header">
                <div>Streams</div>
                <div className="m_ico m_ico--rotate">
                  {!expanded && <div>unfold_more</div>}
                  {expanded && <div>unfold_less</div>}
                </div>
              </div>
            </div>
            <div className="ui_tabs__li ui_tabs__li--right">
          </div>
          </div>
        </div>
      </div> */}

			{playlistId && (
				<>
					<PlaylistTop
						playlistId={playlistId}
						{...getDataById(dataContext, "playlists", playlistId)}
						// handleMenu={() => setMenu()}
					/>
				</>
			)}

			{/* <Playlist playlistId={playlistId} /> */}

			<div className="l_thumbs_day">
				<PerfectScrollbar className={`l_thumbs_ul`}>
					<div className="l_thumbs_ul">
						<ContentList playlistId={playlistId}/>
					</div>
				</PerfectScrollbar>
			</div>

			{/* </div> */}
		</>
	);
}


const PlaylistItemInner = (props) => {
	const dataContext = useData();
	const {
		video,
		space,
		active,
		uniq,
		app,
		sprite,
		metadata,
		history,
		match,
		location,
		key
	} = {...props};

  const sprite_backup = "https://res.cloudinary.com/generalsymbol/image/upload/v1639770770/samples/out_vkcckn.jpg"

	const handleClick = () => {
		// history.push(`/list/${match.params["playlistId"]}/${id}`);
		// console.log(video)
		let path = match["path"];
    path = path.replace(":space", "space");
		path = path.replace(":videoId?", video.id);
		// if (match.params["playlistId"]) {
		// 	path = path.replace(":playlistId?", match.params["playlistId"]);
		// 	history.push(path);
		// } else {
		// 	history.push(path);
		// }
		// console.log(path);
		// history.push(path);
		history.push('/video/' + video.id);
	};
	const imageContainer = useRef();

	const [width, setWidth] = useState(0);
	const [height, setHeight] = useState(0);

	const [dimensions, setDimensions] = React.useState({
		wHeight: window.innerHeight,
		wWidth: window.innerWidth,
	});

	useEffect(() => {
		function handleResize() {
			setDimensions({
				wHeight: window.innerHeight,
				wWidth: window.innerWidth,
			});
		}

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize)
		}
	});

	useEffect(() => {
		if (width !== imageContainer.current.offsetWidth) {
			setWidth(imageContainer.current.offsetWidth);
		}
		if (height !== imageContainer.current.offsetHeight) {
			// setHeight(imageContainer.current.offsetHeight)
			setHeight(width * SCREEN_RATIO);
		}
	}, [imageContainer, match, dimensions]);

	// useEffect(() => {
	//   console.log('getMetadataCategories(dataContext, videoId, sort) = ', getMetadataCategories(dataContext, videoId, 'sort'));
	// }, [])

	return (
		<div
			key={key}
			className="l_thumbs_li"
			onClick={() => handleClick()}
			ref={imageContainer}
		>
			<div className={`ui_video_element ${active ? "active" : ""}`}>
				{/* <div className="ui_video_element_bg" /> */}

				{/* <div className="p_thumbs_ava">
          <img
            src={getImage(getDataById(dataContext, "apps", app, "img"))}
            loading="lazy"
            alt={getDataById(dataContext, "apps", app, "title")}
            className="p_user_ava_img"
          />
        </div> */}

				<CSSthumb
					className="l_thumbs_vid"
					style={{
						// height: width * SCREEN_RATIO,
						backgroundImage: 'none',
						// backgroundImage: `url(https://img.youtube.com/vi/${url}/0.jpg)`,
					}}
				>
					{/* {getDataById(dataContext, "apps", app) && <div className="p_thumbs_ava">
						<img
							src={getImage(getDataById(dataContext, "apps", app, "img"))}
							loading="lazy"
							alt={getDataById(dataContext, "apps", app, "title")}
							className="p_user_ava_img"
						/>
					</div>} */}
					<div className="ui_video_element_timestamp">100{minToHours(video.length)}</div>
					<div className="ui_video_scrubber">
						<HoverScrubber
							width={width}
							height={width * SCREEN_RATIO}
							sprite={sprite ?? API_URL + "/api/v1/video_image/" + video.id}
						/>
					</div>
					{/* <img src={getImage(img, "full")} loading="lazy" sizes="100px" /> */}
				</CSSthumb>

				<div className="ui_video_element_info">
					<div href="#" className="ui_video_element_info_text">
						<div>{video.title}</div>
					</div>
					<UserBadge user={video.created_by}/>

					<div className="ui_video_element_top">
						<div className="ui_video_element_metatop">
							{Boolean(getMetadataCategories(dataContext, video.id, "sort").length) &&
								getMetadataCategories(dataContext, video.id, "sort").map((typeRef) => {
									let typeData = getDataById(dataContext, "metaDataTypes", typeRef);
									let metadataLength = dataContext.data.metadata.filter(
										(oneMetadata) => {
											let r =
												oneMetadata["typeRef"] === typeRef &&
												oneMetadata["videoId"] === video.id;
											return r;
										}
									).length;

									return (
										<>
											{typeData && (
												<div
													key={`tag_${typeData["id"]}`}
													className="ui_video_tag"
													style={{color: typeData["color"]}}
												>
													<div
														className="ui_video_tag_bg"
														style={{backgroundColor: typeData["color"]}}
													/>
													{typeData["title"]}
													<div className="x_meta_title_nums0">
														<div className="x_meta_title_num">
															{metadataLength}
														</div>
													</div>
												</div>
											)}
										</>
									);
								})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const CSSthumb = styled.div`
  /* & .ui_video_scrubber {
    opacity: 0;
  }
  &:hover .ui_video_scrubber {
    opacity: 1;
  } */
`;

export const PlaylistItem = withRouter(PlaylistItemInner);
export const Playlist = withRouter(PlaylistInner);
