import React from "react";
import styled from "styled-components";
import {withRouter} from "react-router-dom";
import {getMetadataCategories} from "../utils";

import {MetadataSection} from "./MetadataSection";
import {useData} from "../contexts/data";

export function MetadataInner(props) {
  const { videoId, history, match, location,
    frame,
    handleFrame
  } = { ...props };
  const dataContext = useData();

  // const HIGHTLIGHTS_ID = "recfZDN8LMltTGGyS";

  // const [frame, setFrame] = useState(0);


  return (
    <>
      {/*<MetadataSection*/}
      {/*  key={`metadata_${HIGHTLIGHTS_ID}`}*/}
      {/*  // key={`metadata_${uniqid()}`}*/}
      {/*  typeRef={HIGHTLIGHTS_ID}*/}
      {/*  // frame={frame}*/}
      {/*  // handleFrame={(n) => {*/}
      {/*  //   setFrame(Math.floor(n));*/}
      {/*  // }}*/}
      {/*  {...props}*/}
      {/*/>*/}

      {/*{Boolean(getMetadataCategories(dataContext, videoId).length) &&*/}
      {/*  getMetadataCategories(dataContext, videoId)*/}
      {/*  .filter((one) => one !== HIGHTLIGHTS_ID)*/}
      {/*  .map((typeRef) => {*/}
      {/*    // debugger*/}
      {/*    return (<>*/}
      {/*        {typeRef && <MetadataSection*/}
      {/*          key={`metadata_${typeRef['id']}`}*/}
      {/*          // key={`metadata_${uniqid()}`}*/}
      {/*          typeRef={typeRef}*/}
      {/*          // frame={frame}*/}
      {/*          // handleFrame={(n) => {*/}
      {/*          //   setFrame(Math.floor(n));*/}
      {/*          // }}*/}
      {/*          {...props}*/}
      {/*        />}*/}
      {/*        </>*/}
      {/*    );*/}
      {/*  })}*/}

      {/* <MetadataAdd
        videoId={videoId}
        categoriesArray={getMetadataCategories(dataContext, videoId)}
      /> */}
    </>
  );
}










export const Metadata = withRouter(MetadataInner);

const CSSscrubber = styled.div`
  height: 32px;
  width: 10px;
  margin-top: -6px;
  background: white;
`;
