import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import "./gs_video.css";
import {HoverScrubber} from "./Scrubber";
import './gs_add.css';
import GsPlayer from "./video-player";
import { SCREEN_RATIO } from "../config";

export const PlayerVideo = (props) => {
  const playerRef = useRef(null)
  const { source, frame, sprite, expanded } = { ...props };
  const [play, setPlay] = useState(false);
  const [thumbShown, setThumbShown] = useState(false);
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    if (play) {
      setTimeout(() => {
        setThumbShown(false);
      }, 100);
    } else {
      setThumbShown(true);
    }
  }, [play]);

  useEffect(() => {
    setOpacity(1);
    setTimeout(() => {
      setOpacity(0);
      setTime(frame)
    }, 2000);
  }, [frame]);

  useEffect(() => {
    console.log('playerRef = ', playerRef);
    console.log('frame = ', frame);
  }, [frame, playerRef]);

  const setTime = (frame) => {
    /* Example usage of the forwarded ref */
    if (playerRef?.current) {
      // let player = playerRef.current.getPlayer();
      // player.currentTime(frame);
    }
  };

  const [width, setWidth] = useState(0);

  const ref = useRef(null);
  useEffect(() => {
    setWidth(ref.current ? ref.current.offsetWidth : 0);
  }, [ref.current]);



  let settings = {
    /* Override defined settings of videojs player */
    aspectRatio: "16:9",
  };

  return (
    <>
      <div
        ref={ref}
        className={`v0`}
        style={{
          height: width * SCREEN_RATIO,
        }}
      >
        <CSSvideo
          opa={opacity}
          className="v_scrubber0 noselect">
          <HoverScrubber
            scrub={false}
            width={width}
            height={width * SCREEN_RATIO}
            externalSprite={frame}
            sprite={sprite}
          />
        </CSSvideo>

        <div style={{ zIndex: 2 }}>
          <GsPlayer
            width={width}
            height={width * SCREEN_RATIO}
            ref={playerRef}
            settings={settings}
            source={source}
          />
        </div>
        {/* {thumbShown && <div className="v_thumb noselect"></div>} */}
      </div>
    </>
  );
}

const CSSvideo = styled.div`
  opacity: ${({ opa }) => opa};
`;

const CSSelement = styled.div`
  color: ${({ theme }) => theme['colorAccent']};
`