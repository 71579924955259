import React, { Fragment, useEffect, useState, useContext, useRef } from 'react'
import { Link, withRouter } from 'react-router-dom'
import styled, { css } from 'styled-components';

export const PlaylistAddButton = ({
  handleStream = () => void (0),
  handleUpload = () => void (0),
}) => {

  const [hovered, setHovered] = useState(false)
  const [hovered2, setHovered2] = useState(false)
  const [hovered3, setHovered3] = useState(false)

  useEffect(() => {
    if (hovered !== hovered2) {
      setTimeout(() => {
        setHovered2(hovered)
      }, 0);
      setTimeout(() => {
        setHovered3(hovered)
      }, 100);
    } else {

    }
  }, [hovered])

  const hoverRef = useRef();

  return (
    <div className="x_add0"
      // style={{ border: '1px solid red' }}
      ref={hoverRef}
      onMouseOver={(e) => {
        // e.stopPropagation(false)
        setHovered(true)
      }}
      onMouseOut={() => setHovered(false)}
    >

      {/* {hovered &&  */}
      <div className="x_add_pop0" hovered={hovered2}>
        <div className="x_add_ul">

          <div
            onClick={() => handleUpload()}
            // hovered={hovered3}
            className="x_add_variant x_add_variant--2"
          // style={{ opacity: hovered2 ? 1 : 0 }}
          >
            <div className="x_add_variant_ico">
              <div className="ico">
                <div>cloud_upload</div>
              </div>
            </div>
            <div>Upload video</div>
          </div>

          <div className="x_add_variant x_add_variant--3"
            onClick={() => handleStream()}
          // style={{ opacity: hovered3 ? 1 : 0 }}
          >
            <div className="x_add_variant_ico">
              <div className="ico">
                <div>videocam</div>
              </div>
            </div>
            <div>Capture screen</div>
          </div>


          {/* <div className="x_add_variant">
            <div className="x_add_variant_ico">
              <div className="ico">
                <div>search</div>
              </div>
            </div>
            <div>Search videos to add</div>
          </div> */}


        </div>
      </div>
      {/* } */}

      <div className="x_add">

      <div className="x_add_but">
          {/* <CSShover /> */}
        <div className="ico">
            <div>add</div>
          </div>
        </div>
      </div>
    </div>
  );
};

// const CSShover = styled.div`
//   position: absolute;
//   top: 0; bottom: 0; right: 0; left: 0;
//   z-index: 2;
// `
// const CSScontainer = styled.div`
//   & .x_add_pop0 {
//     display: none;
//   }
//   &:hover .x_add_pop0 {
//     display: flex;
//   }
// `

const CSShovered = styled.div`
  opacity: ${({ hovered }) => hovered ? 1 : 0};
  transforn: translateY ${({ hovered }) => hovered ? `${-20}px` : 0};
`


export const PlaylistAddButtonOld = () => {
  return (
    <div className="l_thumbs_li">
      <div className="ui_video_element ui_video_element--inactive">
        <div className="ui_video_add0">
          <div className="ui_video_but">
            <div className="ui_video_icon">
              <div className="ico">
                <div>cloud_upload</div>
              </div>
            </div>
            <div>Upload<br />video</div>
          </div>
          <div className="ui_video_but">
            <div className="ui_video_icon">
              <div className="ico">
                <div>videocam</div>
              </div>
            </div>
            <div>Capture<br />screen</div>
          </div>
        </div>
      </div>
    </div>
  );
};

