import React, { Fragment, useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import styled, {css} from 'styled-components';
import { Svg } from './Svg';


export const preloader = `<svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
    <path fill="currentColor" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
      <animateTransform
         attributeName="transform"
         attributeType="XML"
         type="rotate"
         dur="1s"
         from="0 50 50"
         to="360 50 50"
         repeatCount="indefinite" />
  </path>
</svg>`

export const CSSpreloader = styled.div`
  &:after {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background-color: green;
    content: ${(<Svg hight={24}>{preloader}</Svg>)};
  }
`;

export const Preloader = ({ width = 44, height = 44 }) => {
  return (
    <Svg
      style={{ position: "relative", zIndex: 1 }}
      width={width}
      height={height}
      forceHeight={height}
    >
      {preloader}
    </Svg>
  );
};

export const Preloader0 = styled.div`
  height: ${({ height }) => (height ? height : "100%")};
  display: flex;
  z-index: 100;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
`;

