import React from 'react'
import {useData} from "../contexts/data";

export function ContentInfo({ videoID }) {
  const dataContext = useData()
  return (<>
    <div className="l_content_metadata_info">
      <div className="l_content_metadata_sticky">
        <div className="l_content_info_section">
          <div className="l_info_line">
            <div className="l_video_info_title">
              Threads
            </div>
            {/*{Boolean(getMetadataCategories(dataContext, videoID).length) && getMetadataCategories(dataContext, videoID)*/}
            {/*	.map((typeRef) => {*/}
            {/*		// debugger*/}
            {/*		return (<>*/}
            {/*			{typeRef && <ContentInfoLine*/}
            {/*				// key={`metadata_info_${typeRef['id']}`}*/}
            {/*				key={`metadata_${uniqid()}`}*/}
            {/*				typeRef={typeRef}*/}
            {/*				// frame={frame}*/}
            {/*				// handleFrame={(n) => {*/}
            {/*				//   setFrame(Math.floor(n));*/}
            {/*				// }}*/}
            {/*				// {...props}*/}
            {/*			/>}*/}
            {/*		</>);*/}
            {/*	})}*/}
            <div className="l_info_line">
              <div className="l_info_processing">
                Processing...
              </div>
            </div>
          </div>
        </div>
        <div className="l_content_info_section">
          <div className="l_video_info_title">
            <div>Tools used</div>
          </div>
          <div className="l_info_line">
            <div className="l_info_processing">
              Processing...
            </div>
          </div>
        </div>

        <div className="l_content_info_section">
          <div className="l_video_info_title">
            Top contributors
          </div>
          <div className="l_info_line">
            <div className="l_info_processing">
              Processing...
            </div>
          </div>
        </div>
      </div>
    </div>
  </>)
}

export function ContentInfoLine({ typeRef, key }) {
  const dataContext = useData();
  // const info = getDataById(dataContext, 'metaDataTypes', typeRef);
  const info = {}
  return (<div className="l_info_sum0" key={key}>
    <div className="l_info_sum">
      <div className="x_meta_title">
        {info?.title}
        <div className="x_meta_title_nums0">
          <div className="x_meta_title_num">
            <div>4</div>
          </div>
        </div>
        <div className="x_meta_title_num x_meta_title_num--comments">
          <div className="x_meta_comment_icon">
            <div>comment</div>
          </div>
          <div>3</div>
        </div>
      </div>
      <div className="x_meta_menu">
        <div className="x_button_icon">
          <div>more_vert</div>
        </div>
      </div>
    </div>
  </div>)
}