import React, { useState } from "react";
import { useApi } from "./api";
import { gql } from "@apollo/client";
import { Redirect } from "react-router-dom";

const AuthContext = React.createContext();
const useAuth = () => React.useContext(AuthContext);

const PASSWORD_RECOVERY_STEP1_QUERY = gql`
  mutation ($email: String!) {
    passwordRecoveryStep1(email: $email) {
      status
    }
  }
`;

const PASSWORD_RECOVERY_STEP2_QUERY = gql`
  mutation ($token: String!, $password: String!) {
    passwordRecoveryStep2(token: $token, password: $password) {
      status
    }
  }
`;

const LOGIN_QUERY = gql`
  mutation ($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
      token
      owned_by {
        id
        name
        email
      }
    }
  }
`;

const CREATE_ACCOUNT_QUERY = gql`
  mutation ($name: String!, $email: String!, $password: String!) {
    createAccount(name: $name, email: $email, password: $password) {
      id
      name
      email
    }
  }
`;

const ACCOUNT_ACTIVATION_QUERY = gql`
  mutation ($token: String!) {
    accountActivation(token: $token) {
      activated
    }
  }
`;

const AuthProvider = (props) => {
  const apiContext = useApi();
  const [session, setSession] = useState(() =>
    JSON.parse(localStorage.getItem("user_session"))
  );
  const [user, setUser] = useState(() => session?.user);
  const [authed, setAuthed] = useState(() => session?.id != undefined);

  const loadUserSession = () => {
    return JSON.parse(localStorage.getItem("user_session"));
  };

  const storeUserSession = (data) => {
    apiContext.reset();
    if (data) {
      localStorage.setItem("user_session", JSON.stringify(data));
      localStorage.setItem("token", data?.token);
    } else {
      localStorage.removeItem("user_session");
      localStorage.removeItem("token");
    }
    setSession(data);
    setUser(data?.user);
    setAuthed(Boolean(data?.id));
  };

  const passwordRecoveryStep1 = async (email) => {
    // console.log('auth login with email', user.email)
    return new Promise(async (resolve, reject) => {
      const result = await apiContext
        .mutate({
          mutation: PASSWORD_RECOVERY_STEP1_QUERY,
          variables: {
            email: user.email,
          },
        })
        .then((result) => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const passwordRecoveryStep2 = async (token, password) => {
    // console.log('auth login with email', user.email)
    return new Promise(async (resolve, reject) => {
      const result = await apiContext
        .mutate({
          mutation: PASSWORD_RECOVERY_STEP2_QUERY,
          variables: {
            token: token,
            password: password,
          },
        })
        .then((result) => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const login = async (user) => {
    console.log("auth login with email", user.email);
    return new Promise(async (resolve, reject) => {
      try {
        await apiContext.reset();
        const result = await apiContext.mutate({
          mutation: LOGIN_QUERY,
          variables: {
            email: user.email,
            password: user.password,
          },
        });
        const userSession = result.login;
        console.log("logged in with session id", userSession.id);
        storeUserSession(userSession);
        resolve(userSession);
      } catch (err) {
        console.log("login error", err);
        reject(err);
      }
    });
  };

  const logout = async () => {
    return new Promise((resolve, reject) => {
      storeUserSession(null);
      resolve();
    });
  };

  const createAccount = ({ name, email, password }, onSuccess, onFail) => {
    console.log("create account with email", email);
    return new Promise((resolve, reject) => {
      apiContext
        .mutate({
          mutation: CREATE_ACCOUNT_QUERY,
          variables: {
            name,
            email,
            password,
          },
        })
        .then((result) => {
          const user = result.createAccount;
          resolve(user);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const accountActivation = (token) => {
    console.log("account activation", token);
    return new Promise((resolve, reject) => {
      apiContext
        .mutate({
          mutation: ACCOUNT_ACTIVATION_QUERY,
          variables: {
            token,
          },
        })
        .then((result) => {
          const activation = result.accountActivation;
          resolve(activation);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const context = {
    authed,
    user,
    session,
    handles: {
      login,
      logout,
      createAccount,
      accountActivation,
      loadUserSession,
			passwordRecoveryStep1,
			passwordRecoveryStep2
    },
  };

  return <AuthContext.Provider value={{ ...context }} {...props} />;
};

const RequireAuth = ({ children }) => {
  const { session } = useAuth();
  return session?.token ? children : <Redirect to={"/login"} />;
};

export { AuthProvider, useAuth, RequireAuth };
