import React from "react";


export const defaultIcon = `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.43746 15.0207C7.62257 15.0207 8.58329 14.0599 8.58329 12.8748C8.58329 11.6897 7.62257 10.729 6.43746 10.729C5.25235 10.729 4.29163 11.6897 4.29163 12.8748C4.29163 14.0599 5.25235 15.0207 6.43746 15.0207ZM12.875 15.0208C14.0601 15.0208 15.0208 14.0601 15.0208 12.875C15.0208 11.6899 14.0601 10.7292 12.875 10.7292C11.6899 10.7292 10.7292 11.6899 10.7292 12.875C10.7292 14.0601 11.6899 15.0208 12.875 15.0208ZM21.4583 12.875C21.4583 14.0601 20.4976 15.0208 19.3125 15.0208C18.1274 15.0208 17.1667 14.0601 17.1667 12.875C17.1667 11.6899 18.1274 10.7292 19.3125 10.7292C20.4976 10.7292 21.4583 11.6899 21.4583 12.875Z" fill="currentColor"/>
</svg>`


export const Svg = ({
  name = "defaultIcon",
  width = 44,
  height = 44,
  forceHeight,
  color,
  png,
  children,
  style,
}) => {
  // let icon = children ? children.replace(/currentColor/g, color) : defaultIcon;
  let image = png;

  // try {
  width = width ? width : children.split('width="')[1].split('"')[0]; //50
  height = height ? height : children.split('height="')[1].split('"')[0]; //44
  forceHeight = forceHeight ? forceHeight : height;
  let icon = children ? children : defaultIcon;

  if (color)
    icon = icon.replace(
      /fill="((.*?))(?!fill="none")\b\S+"/gim,
      `fill="${color}"`
    );

  let encoded = window.btoa(icon);

  // } catch (e) {}
  return (
    <div
      style={{
        display: "inline-flex",
        width: `${width}px`,
        height: `${forceHeight}px`,
        alignItems: "center",
        ...style
      }}
    >
      <div
        style={{
          color: color,
          width: `${width}px`,
          height: `${height}px`,
          transition: "all 200ms ease-in-out",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "50% 50%",
          backgroundSize: "contain",
          backgroundImage: "url(data:image/svg+xml;base64," + encoded + ")",
        }} />
    </div>
  );
};
