import React, {useEffect, useState} from "react";

export const useMousePos = (el) => {
    el = el || document;
    // console.log('el = ', el);
    const [mousePos, setMousePos] = useState({});
    // if (!el) return {posX: 0, posY: 0}
    // let rect = el ? el.getBoundingClientRect() : {left: 0, top: 0};
    useEffect(() => {
        const getMousePos = (e) => {
            try {
                let rect =
                    typeof el === "object" && el !== null && "getBoundingClientRect" in el ?
                    el.getBoundingClientRect() :
                    { left: 0, top: 0 };
                // let rect = e.target.getBoundingClientRect();
                const posX = e.clientX - rect.left;
                const posY = e.clientY - rect.top;
                setMousePos({ posX, posY });
            } catch (e) {
                console.log("e = ", e);
            }
        };
        el.addEventListener("mousemove", getMousePos);
        return function cleanup() {
            el.removeEventListener("mousemove", getMousePos);
        };
    });
    return mousePos;
};