import "./styles/controls.module.css";
import "./styles/main.module.css";
import "./styles/playbackrate.module.css";
import "./styles/progressbar.module.css";
import "./styles/qualityselector.module.css";
import "./styles/timecontrols.module.css";
import "./styles/volume.module.css";

import GsPlayer from "./components/GsPlayer";

export default GsPlayer;
