import React from "react";
import styled from "styled-components";


export const UnderConstruction = ({web, mobileh, mobile}) => {
  return (<><Under className="under-construction">
      <div>Mobile view is under construction</div>
    </Under>
    </>);
}


const Under = styled.div`
          position: fixed;
          left: 0%;
          top: 0%;
          right: 0%;
          bottom: 0%;
          z-index: 100;
          display: none;
          -webkit-box-pack: center;
          -webkit-justify-content: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          background-color: #fff;
          font-size: 2em;
          line-height: 1em;
          text-align: center;
`

