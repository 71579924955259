import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { LayoutWithHeader } from "../components/LayoutWithHeader";
import { useAuth } from "../contexts/auth";
import { data } from '../components/data';
import uniqid from "uniqid";
import { UIinput } from "../uikit/UIinput";

const ProfileInner = () => {
  const authContext = useAuth()
  return (
    <>
      <LayoutWithHeader title={"Account"} background={'settings'}>

        <div className="l_page_w">


          <SettingsProfile />
          <SettingsSpaces />

        </div>
      </LayoutWithHeader>
    </>
  )
}



export function SettingsProfile(props) {

  const [name, setName] = useState(false);
  const [editName, setEditName] = useState(false);
  const [password, setPassword] = useState(false);
  const [editPassword, setEditPassword] = useState(false);



  //TODO: handle save name. Errors and success — show Toast
  const handleSaveName = newName => { void (0) }

  //TODO: handle save password. Errors and success — show Toast
  const handleSavePassword = newName => { void (0) }

  return (
    <>

      <div className="padding padding--sm">
        <div className="l_section l_section--padding">
          <div className="line">
            <h1>Profile</h1>
          </div>
          <div className="line">


            {/* TODO: Avatar upload */}
            {/* <div className="l_pr_columns l_pr_columns--table">
              <div className="l_pr_col">
                <div>Avatar</div>
              </div>
              <div className="l_pr_col l_pr_col--r">
                <div className="x_avatar_update0">
                  <img src="https://assets.website-files.com/627b3c839e056e0d434545cd/627b3c839e056e2d7d4545e6_ava.png" loading="lazy" sizes="(max-width: 479px) 7vw, (max-width: 1439px) 32px, (max-width: 1919px) 2vw, 32px" srcSet="https://assets.website-files.com/627b3c839e056e0d434545cd/627b3c839e056e2d7d4545e6_ava-p-500.png 500w, https://assets.website-files.com/627b3c839e056e0d434545cd/627b3c839e056e2d7d4545e6_ava.png 683w" alt className="p_user_ava_img" />
                </div>
              </div>
              <div className="l_pr_col l_pr_col--r">
                <div className="t_link">
                  <div>Upload new</div>
                </div>
              </div>
            </div> */}


            {!editName && <div className="l_pr_columns l_pr_columns--table">
              <div className="l_pr_col">
                <div>Display name</div>
              </div>
              <div className="l_pr_col l_pr_col--r">
                <div>Misha Miroshnikov</div>
              </div>
              <div className="l_pr_col l_pr_col--r">
                <a href="#" className="t_link w-inline-block"
                  onClick={() => setEditName(true)}
                >
                  <div>Edit</div>
                </a>
              </div>
            </div>}


            {editName && <div className="l_pr_container l_pr_container--new">
              <div className="l_pr_columns">
                <div className="l_pr_col">
                  Display name
                </div>
                <div className="l_pr_col l_pr_col--r">
                  <div className="c_m_section">

                    <UIinput
                      compact
                      label="Display name"
                      defaultValue="Misha Miroshnikov"
                      theme={{ background: 'white' }}
                    />
                    {/* <div className="x_input">
                      <div className="x_input_placeholder">Misha Miroshnikov</div>
                    </div> */}


                  </div>
                </div>
                <div className="l_pr_col l_pr_col--r">
                  <div className="x_button_container_left">

                    <div className="x_button x_button--main"
                      onClick={() => {
                        handleSaveName(name)
                        setEditName(false)
                      }}
                    >
                      <div>Save</div>
                      <div className="x_button_icon">
                        <div>done</div>
                      </div>
                    </div>

                    <div className="x_button"
                      onClick={() => setEditName(false)}
                    >
                      <div>Cancel</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>}


            <div className="l_pr_columns l_pr_columns--table">
              <div className="l_pr_col">
                <div>Email</div>
              </div>
              <div className="l_pr_col l_pr_col--r">
                <div>mikoza@gmail.com</div>
              </div>
            </div>



            {!editPassword && <div className="l_pr_columns l_pr_columns--table">
              <div className="l_pr_col">
                <div>Password</div>
              </div>
              <div className="l_pr_col l_pr_col--r">
                <div>************</div>
              </div>
              <div className="l_pr_col l_pr_col--r">
                <a href="#" className="t_link w-inline-block"
                  onClick={() => setEditPassword(true)}
                >
                  <div>Change</div>
                </a>
              </div>
            </div>}


            {editPassword && <div className="l_pr_container l_pr_container--new">
              <div className="l_pr_columns">
                <div className="l_pr_col">
                  <div>New password</div>
                </div>
                <div className="l_pr_col l_pr_col--r">
                  <div className="c_m_section">
                    <UIinput
                      compact
                      label="Password"
                      defaultValue="***********"
                      theme={{ background: 'white' }}
                    />


                  </div>
                </div>
                <div className="l_pr_col l_pr_col--r" />
              </div>
              <div className="l_pr_columns">
                <div className="l_pr_col">
                  <div>New password again</div>
                </div>
                <div className="l_pr_col l_pr_col--r">
                  <div className="c_m_section">


                    <UIinput
                      compact
                      label="Password"
                      defaultValue="***********"
                      theme={{ background: 'white' }}
                    />

                  </div>
                </div>
                <div className="l_pr_col l_pr_col--r">
                  <div className="x_button_container_left">
                    <div className="x_button x_button--main"
                      onClick={() => {
                        handleSavePassword(password);
                        setEditPassword(false)
                      }}
                    >
                      <div>Save</div>
                      <div className="x_button_icon">
                        <div>done</div>
                      </div>
                    </div>
                    <div className="x_button"
                      onClick={() => setEditPassword(false)}
                    >
                      <div>Cancel</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>}



          </div>
          <div className="line" />
        </div>
      </div>


    </>
  )
}


export function SettingsSpaces(props) {
  return (
    <>

      <div className="l_page_b l_page_b--bg">
        <div className="padding padding--0">
          <div className="l_section l_section--padding">
            <div className="line">
              <h1>Spaces & Billing</h1>
            </div>



            {data['spaces'].map(one => {
              return (<div className="line" key={uniqid()}>
                <div className="l_pr_container">
                  <div className="line">
                    <h3>{one['title']}</h3>
                  </div>
                  <div className="line">
                    <div className="l_pr_columns l_pr_columns--table">
                      <div className="l_pr_col">
                        <div>Plan:</div>
                      </div>
                      <div className="l_pr_col l_pr_col--r">
                        Free plan
                      </div>
                    </div>
                    <div className="l_pr_columns l_pr_columns--table">
                      <div className="l_pr_col">
                        <div>Next billing:</div>
                      </div>
                      <div className="l_pr_col l_pr_col--r">
                        —
                      </div>
                    </div>
                    <div className="l_pr_columns l_pr_columns--table">
                      <div className="l_pr_col">
                        <div>Billed:</div>
                      </div>
                      <div className="l_pr_col l_pr_col--r">
                        —
                        {/* <div>****&nbsp;****&nbsp;****&nbsp;6124</div> */}
                      </div>
                    </div>


                  </div>
                  {/* <div className="line">
                  <div className="flex">
                    <div className="x_button x_button--main">
                      <div>Edit settings</div>
                    </div>
                  </div>
                </div> */}
                </div>
              </div>)
            })}





          </div>
        </div>
      </div>

    </>
  )
}



export const Profile = withRouter(ProfileInner);