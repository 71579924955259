import React, {useState} from "react";
import {withRouter} from "react-router-dom";
import {LayoutWithHeader} from "../components/LayoutWithHeader";
import {useAuth} from "../contexts/auth";
import {UIbutton} from "../uikit/Uikit";
import {UIinput} from "../uikit/UIinput";
import {useData} from "../contexts/data";

const SearchInner = ({space}) => {
	const authContext = useAuth()
	const dataContext = useData()
	const [query, setQuery] = useState()

	const handleSearch = () => {
		console.log({query})
	}

	return (
		<>
			<LayoutWithHeader title={space.title}>
				<div className="line">
					<UIinput label={"Search Text"} defaultValue={query}
									 onChange={(value) => setQuery(value)}
									 onEnter={handleSearch}/>
				</div>
				<div className="line line--center">
					<UIbutton onClick={handleSearch}>Search</UIbutton>
				</div>
			</LayoutWithHeader>
		</>
	)
}


export const Search = withRouter(SearchInner);