import React, {useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import {LayoutWithHeader} from "../components/LayoutWithHeader";
import {useData} from "../contexts/data";
import {Player} from "../components/Player";

const VideoViewerInner = ({videoID}) => {
	const dataContext = useData()
	const [video, setVideo] = useState(null)
	const [error, setError] = useState(null)

	useEffect(() => {
		dataContext.handles.videoByID(videoID).then((result) => {
			setVideo(result)
			console.log('video viewer', result)
		}).catch((err) => setError(err.message))
	}, [videoID])
	return (
		<LayoutWithHeader title={"Video Viewer"}>
			{video && (
				<Player videoID={video.id}/>
			)}
		</LayoutWithHeader>
	)
}

export const VideoViewer = withRouter(VideoViewerInner)