import {createContext, useContext, useEffect, useState} from "react";

const VersionContext = createContext()
const useVersion = () => useContext(VersionContext)

const VersionProvider = (props) => {
	const toggleProd = {}

	const toggleDev = {
		playlistFilters: true,
		playlistImage: true,
		playlistIcon: true,
		playlistAuthor: true,
	}

	const [version, setVersion] = useState(false); //dev=true//prod=false
	const [toggle, setToggle] = useState(version === false ? toggleProd : toggleDev); //dev//prod

	useEffect(() => {
		!version ? setToggle(toggleProd) : setToggle(toggleDev)
	}, [version])

	const context = {
		version,
		toggle,
		handles: {
			setVersion,
			setToggle
		}
	}

	return (
		<VersionContext.Provider value={{...context}} {...props}/>
	)
}

export {VersionProvider, useVersion}