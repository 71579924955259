import React, {useState} from "react";
import {UIinput} from "../uikit/UIinput";
import {useHistory, withRouter} from "react-router-dom";
import {LayoutWithHeader} from "../components/LayoutWithHeader";
import {Preloader} from "../uikit/Preloader";
import {UIbutton} from "../uikit/Uikit";
import {useData} from "../contexts/data";



const VideoCreateInner = () => {
	const dataContext = useData()
	const [title, setTitle] = useState()
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(false)
	const history = useHistory()

	const handleCreateVideoRecord = async () => {
		try {
			setError(null)
			console.log("title", title)
			if (title.length == 0) {
				setError("Title required")
				return
			}
			setLoading(true)
			const video = await dataContext.handles.createVideo({title})
			history.replace(`/video/` + video.id + `/record`)
		} catch (err) {
			setError(err.message)
		} finally {
			setLoading(false)
		}
	}

	const handleCreateVideoUpload = async () => {
		try {
			setError(null)
			console.log("title", title)
			if (title.length == 0) {
				setError("Title required")
				return
			}
			setLoading(true)
			const video = await dataContext.handles.createVideo({title})
			history.replace(`/video/` + video.id + `/upload`)
		} catch (err) {
			setError(err.message)
		} finally {
			setLoading(false)
		}
	}

	return (
		<LayoutWithHeader title={"Create Video"}>
			{loading && (
				<Preloader>
					<Preloader/>
				</Preloader>
			)}
			{!loading && (<>
				{error && (
					<div className="line">
						ERROR: {error}
					</div>
				)}
				<div className="line">
					<UIinput label={"Title"} defaultValue={title}
									 onChange={(value) => setTitle(value)}
									 onEnter={handleCreateVideoRecord}
					/>
				</div>
				<div className="line line--center">
					<UIbutton onClick={handleCreateVideoRecord}>Record Screen</UIbutton>
				</div>
				<div className="line line--center">
					<UIbutton onClick={handleCreateVideoUpload}>Upload Video</UIbutton>
				</div>
			</>)}
		</LayoutWithHeader>
	)
}

export const VideoCreate = withRouter(VideoCreateInner);