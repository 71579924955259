import React, {useState} from 'react'
import "react-scrubber/lib/scrubber.css";
import {Scrubber} from "react-scrubber";
import {CSSscrubberStyle} from './MetadataSection';
import {useData} from "../contexts/data";

export function ScrubberLine(props) {

  // const {frame, handleFrame} = {...props}

  const dataContext = useData();

  const [frame, setFrame] = useState(0);

  const onScrubChange = (n) => {
    setFrame(Math.floor(n));
    // handleFrame(Math.floor(n));
  }

  return (
    <>

      <CSSscrubberStyle color='red' style={{width: 500,}}>
        <div className="scrubber-container">
          <Scrubber
            min={0}
            max={100}
            // value={frameLocal}
            value={frame}
            onScrubChange={onScrubChange}
          />
        </div>
      </CSSscrubberStyle>


    </>
  )
}