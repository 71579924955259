import videojs from "video.js";

import {MAX_BROWSER_SPEED, MP4_SOURCE_TYPE} from "../../constants";
import {
	getFileNameBySpeed,
	getSrcUrl,
	hideQualitySelector,
	showQualitySelector,
	updatePlaybackRate,
} from "../../utils";

const MenuItem = videojs.getComponent("MenuItem");

class SpeedSelectorItem extends MenuItem {
  constructor(player, options) {
    super(player, options);
    this.selectable = true;
    this.update();
  }

  handleClick() {
    const selectedSpeed = this.options_.factor;
    const currentSpeed = this.player().displaySpeed;

    const timestamp = this.player().currentTime();
    let nextTimestamp;

    if (selectedSpeed === currentSpeed) {
      return;
    }

    if (selectedSpeed <= MAX_BROWSER_SPEED) {
      if (currentSpeed > MAX_BROWSER_SPEED) {
        const fileName = getFileNameBySpeed(1);
        const source = getSrcUrl(this.player().src(), fileName);

        this.player().src({
          src: source,
          type: MP4_SOURCE_TYPE,
        });

        this.player().currentFile = fileName;
        nextTimestamp = timestamp * currentSpeed;
        showQualitySelector(this.player());
      }

      updatePlaybackRate(this.player(), selectedSpeed);
    }

    if (selectedSpeed > MAX_BROWSER_SPEED) {
      const fileName = getFileNameBySpeed(selectedSpeed);
      const srcUrl = getSrcUrl(this.player().src(), fileName);

      this.player().src({
        src: srcUrl,
        type: MP4_SOURCE_TYPE,
      });

      this.player().currentFile = fileName;
      updatePlaybackRate(this.player(), 1);
      hideQualitySelector(this.player());

      if (currentSpeed <= MAX_BROWSER_SPEED) {
        nextTimestamp = timestamp / selectedSpeed;
      } else {
        nextTimestamp = (timestamp * currentSpeed) / selectedSpeed;
      }
    }

    if (nextTimestamp) {
      this.player().currentTime(nextTimestamp);
    }

    this.player().displaySpeed = selectedSpeed;
    this.player().trigger("updateSpeed");
    this.player().play();

    this.options_.controller.triggerItemUpdate();
  }

  update() {
    this.selected(this.player().displaySpeed === this.options_.factor);
  }
}

export default SpeedSelectorItem;
