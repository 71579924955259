import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {PlaylistItem} from "./Playlist";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import {useData} from "../contexts/data";
import {Preloader, Preloader0} from "../uikit/Preloader";
import {UIbutton} from "../uikit/Uikit";

const ContentListInner = ({playlistId, userId, videoId}) => {
	const dataContext = useData()
	const [videos, setVideos] = useState(null)
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)

	const load = () => {
		setLoading(true)
		dataContext.handles
			.videos({offset: 0, limit: 100, reverse: true})
			.then((result) => setVideos(result))
			.catch((err) => {
				console.log("contentlist error", err)
				setError(err)
			})
			.finally(() => setLoading(false))
	}

	useEffect(() => load(), [videos])


	return (
		<>
			<div className="l_thumbs_day">
				{loading && (
					<Preloader0>
						<Preloader/>
					</Preloader0>
				)}
				{!loading && error && (
					<Preloader0>
							<div>Error loading videos.</div>
						<br/>
							<div><UIbutton onClick={() => load()}>Retry</UIbutton></div>
						</Preloader0>
				)}
				{videos && (
					<PerfectScrollbar className={`l_thumbs_ul`}>
						{
							videos.map((one) => {
									return (
										<PlaylistItem
											key={`playlist_${one["id"]}`}
											active={videoId === one.id}
											video={one}
										/>
									);
								})
						}
					</PerfectScrollbar>
				)}
			</div>
		</>
	);
}


export const ContentList = withRouter(ContentListInner)