import {
	IDENTIFIER_100X,
	IDENTIFIER_10X,
	IDENTIFIER_1X,
	IDENTIFIER_25X,
	IDENTIFIER_50X,
	SOURCE_EXTENSION,
} from "./constants";

export const showQualitySelector = (player) => {
  const { controlBar } = player;
  const fullscreenToggle = controlBar.getChild("fullscreenToggle").el();

  controlBar
    .el()
    .insertBefore(
      controlBar.addChild("QualitySelectorMenu").el(),
      fullscreenToggle
    );
};

export const hideQualitySelector = (player) => {
  const { controlBar } = player;
  controlBar.removeChild("QualitySelectorMenu");
};

export const getSrcUrl = (source, fileName) => {
  if (!source || !fileName) return null;

  let lastIndexOfUrl = source.lastIndexOf("/");
  let url = source.substring(0, lastIndexOfUrl);

  return `${url}/${fileName}${SOURCE_EXTENSION}`;
};

export const getFileNameBySpeed = (speedFactor) => {
  switch (speedFactor) {
    case 1:
      return IDENTIFIER_1X;
    case 10:
      return IDENTIFIER_10X;
    case 25:
      return IDENTIFIER_25X;
    case 50:
      return IDENTIFIER_50X;
    case 100:
      return IDENTIFIER_100X;
    default:
      return null;
  }
};

export const updatePlaybackRate = (player, playbackRate) => {
  player.playbackRate(playbackRate);
  player.defaultPlaybackRate(playbackRate);
};

export const showSpeedSelector = (player) => {
  const { controlBar } = player;
  const volumePanel = controlBar.getChild("volumePanel").el();

  controlBar
    .el()
    .insertBefore(controlBar.addChild("SpeedSelectorMenu").el(), volumePanel);
};

export const getInitialSource = (directory) => {
  if (!directory) return null;

  if (directory.includes(".mp4")) return directory;

  const fileName = IDENTIFIER_1X;
  if (directory.slice(-1) === "/") {
    return `${directory}${fileName}${SOURCE_EXTENSION}`;
  } else {
    return `${directory}/${fileName}${SOURCE_EXTENSION}`;
  }
};
