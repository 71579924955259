import React, { Fragment, useEffect, useState, useContext } from 'react'
export const data = {
  spaces: [
    // {
    //   uniq: 'personal',
    //   type:'personal',
    //   title: 'Personal space',
    //   titleShort: 'P',
    //   color: '#F2B448'
    // },
    {
      uniq: 'space',
      type:'managed',
      title: 'My team',
      titleShort: '',
      color: '#06a19a'
    }

  ],


  projects: [
    {
      uniq: 'gs',
      title: 'General Symbol Prototype',
      color: 'teal',
      icon: 'home',
      img: 'https://assets.website-files.com/613b3c94cc430e5cecdc0c1e/613b4456d85bf111d71b0f11_green.png',
    },
    {
      uniq: 'rc',
      title: 'RC Racer 2021',
      color: 'red',
      icon: 'home',
      img: 'https://assets.website-files.com/61785133118e574036320746/617ba6f68653f362ba29a9ba_Screen%20Shot%202021-10-29%20at%2012.46.34%20AM.png',
    },
    {
      uniq: 'ustoy',
      title: '2021 US Toy Dev',
      color: 'blue',
      icon: 'home',
      img: 'https://i.guim.co.uk/img/media/f759cc2fab05664bca943dad0adc330cd0984d5e/99_0_767_959/master/767.jpg?width=300&quality=45&auto=format&fit=max&dpr=2&s=91cb5a8927dc4a6e0b44e7f6abfecef4',
    },
  ],



  users: [

    {
      uniq: 'danny',
      name: 'Danny Andreev',
      title: 'Developer',
      img: 'https://ca.slack-edge.com/T02JHNY84MN-U02J6MPQH7B-12e86b3e1580-192',
      log: [
        {
          project: 'gs',
          date: '10/27/2021',
          start: '12:00',
          end: '18:10',
          video: {
            uniq: 'ifdLOWd05tM',
            url: 'https:/ /www.youtube.com/embed/ifdLOWd05tM',
          },
          comment: {
            text: 'Finalizing'
          }
        }
      ]
    },


    {
      uniq: 'joe',
      name: 'Joseph Douglas',
      title: 'Developer',
      img: 'https://ca.slack-edge.com/T02JHNY84MN-U02HMBC40A2-65b26da2c135-192',
      log: [
        {
          project: 'rc',
          date: '10/26/2021',
          start: '10:00',
          end: '18:10',
          video: {
            uniq: 'ifdLOWd05tM',
            url: 'https://www.youtube.com/embed/ifdLOWd05tM',
          },
          comment: {
            text: 'Finalizing'
          }
        },
        {
          project: 'rc',
          date: '10/27/2021',
          start: '14:00',
          end: '22:10',
          video: {
            uniq: 'ifdLOWd05tM',
            url: 'https://www.youtube.com/embed/ifdLOWd05tM',
          },
          comment: {
            text: 'Finalizing'
          }
        }
      ]
    },

    {
      uniq: 'misha',
      name: 'Misha Miroshnikov',
      title: 'Designer/Programmer',
      img: 'https://assets.website-files.com/61785133118e574036320746/6178c2817f2e698567910a6e_ava.png',
      log: [
        {
          project: 'gs',
          date: '10/26/2021',
          start: '10:15',
          end: '14:10',
          video: {
            uniq: 'ifdLOWd05tM',
            url: 'https://www.youtube.com/embed/ifdLOWd05tM',
          },
          comment: {
            text: 'Finalizing'
          }
        },
        {
          project: 'gs',
          date: '10/27/2021',
          start: '10:15',
          end: '12:10',
          video: {
            uniq: 'ifdLOWd05tM',
            url: 'https://www.youtube.com/embed/ifdLOWd05tM',
          },
          comment: {
            text: 'Finalizing'
          }
        },
        {
          project: 'gs',
          date: '10/27/2021',
          start: '16:15',
          end: '19:10',
          video: {
            uniq: 'ifdLOWd05tM',
            url: 'https://www.youtube.com/embed/ifdLOWd05tM',
          },
          comment: {
            text: 'Finalizing'
          }
        },
        {
          project: 'ustoy',
          date: '10/27/2021',
          start: '19:30',
          end: '22:00',
          video: {
            uniq: 'ifdLOWd05tM',
            url: 'https://www.youtube.com/embed/ifdLOWd05tM',
          },
          comment: {
            text: 'Finalizing'
          }
        }
      ]
    },
  ]
}