import React from "react";
import {withRouter} from "react-router-dom";
import {LayoutWithHeader} from "../components/LayoutWithHeader";
import {useAuth} from "../contexts/auth";

const SharedInner = () => {
	const authContext = useAuth()
	return (
		<>
			<LayoutWithHeader title={"Shared"}>
				<div>Not Implemented Yet.</div>
			</LayoutWithHeader>
		</>
	)
}


export const Shared = withRouter(SharedInner);