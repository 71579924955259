import React from "react";
import {MenuMain} from "./MenuMain";
import PerfectScrollbar from "react-perfect-scrollbar";
import { PlaylistTop } from './PlaylistTop';

export const LayoutWithHeader = ({ title, children, background }) => {
	return (
		<div className="b0">
			<div className="l_bg"></div>
			<div className="l_c">
				<MenuMain/>
				<div className="l_c_inner">

          {background === 'settings' && <div class="l_bg l_bg--sett" />}


					<div className="l_catalog">
						<div className="l_catalog_inner">
              <div className="l_playlist">




                <PlaylistTop
                  title={title}
                />

                <PerfectScrollbar className={`l_thumbs_day`}>
                  {children}
                </PerfectScrollbar>


							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}