import React, {useEffect, useState} from "react";
import {useHistory, withRouter} from "react-router-dom";
import {Metadata} from "./Metadata";


import PerfectScrollbar from "react-perfect-scrollbar";
import {PlayerVideo} from "./PlayerVideo";
import {ContentInfo} from './ContentInfo';
import "react-perfect-scrollbar/dist/css/styles.css";
import { useData } from "../contexts/data";
import { Preloader, Preloader0 } from "../uikit/Preloader";
import {UIbutton} from "../uikit/Uikit";
import { PlayerInfo } from "./PlayerInfo";

export function PlayerInner({videoID, match}) {
	const dataContext = useData();
	const [error, setError] = useState(null)
	const [video, setVideo] = useState(null)
	const [ready, setReady] = useState(false)
	const [source, setSource] = useState(null)

	useEffect(() => {
		dataContext.handles.videoByID(videoID).then((result) => {
			setVideo(result)
			setSource(null)
		}).catch((err) => setError(err.message))
	}, [videoID])

	useEffect(() => {
		if (video) {
			dataContext.handles.videoSource(videoID).then((result) => {
				setSource(result)
			})
		}
	}, [video])

	useEffect(() => {
		setReady(video && source && !error)
	}, [video, source, error])

	const [expanded, setExpanded] = useState(<true></true>);

	const [duration, setDuration] = useState(0);

	const getInfo = (d) => setDuration(d);

	const sprite = video?.sprite;

	const handleClick = () => {
		// history.push(`/list/${match.params["playlistId"]}/${id}`);
		// let path = match["path"];
		// history.push(path.replace("/:videoID", ""));
	};

	return (<>
		<div className="l_c_v l_c_v--video">
			<div className="l_content">
				{error && (
					<>
						<div className="c0">Error: {error}</div>
					</>
				)}
				{!ready && !error && (
					<Preloader0>
						<Preloader/>
					</Preloader0>
				)}
				{ready && (<PlayerView match={match} video={video} source={source} expanded={true}/>)}
			</div>
		</div>
	</>);
}




export const PlayerView = ({match, video, source, expanded = false}) => {
	const [frame, setFrame] = useState(0);
	const history = useHistory()

	const handleClose = () => {
		console.log('close')
		let path = match["path"];
    path = path.replace(":space", "space")
		path = path.replace(":videoId", "");
		history.push(path);
	}

	return (
		<>
			<div onClick={handleClose} style={{position:'absolute',right:'1em',top:'1em',
				cursor:'pointer',display:'block',backgroundColor:'white',zIndex:100,opacity:'20%'}}>
				<div className="ico">
					<div>close</div>
				</div>
			</div>





			{!(video && source) && (<div>No video information</div>)}


      {video && source && (
				<>
					<div className="l_content_player">
						<div className="l_player_width">

							<PlayerVideo
								expanded={expanded}
								key={video.id}
								// getInfo={getInfo}
								setTime
								source={source}
								frame={frame}
								// sprite={sprite}
							/>

						</div>
						<PlayerInfo video={video} />
					</div>

					<div className="l_content_metadata">
						<PerfectScrollbar className="l_video_meta_scroll">
							<div className="l_video_meta_scroll">
								<Metadata
									videoId={video.id}
									frame={frame}
									handleFrame={(n) => {
										setFrame(Math.floor(n));
									}}
								/>
							</div>
						</PerfectScrollbar>
						<ContentInfo videoID={video?.id}/>
					</div>
				</>)}
		</>
	)
}











export const Player = withRouter(PlayerInner);
