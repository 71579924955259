import React from 'react'
import styled from 'styled-components';
import {useVersion, VersionContext} from "../contexts/version";

// This function takes a component...

export const withVersion = Component => ({ ...props }) => {
  const versionContext = useVersion();
  return (<Component toggle={versionContext.toggle} {...props} />)
};

export const VersionToggle = () => {
  const versionContext = useVersion();
  return (<></>)
  // return (<CSSutility
  //   onClick={() => {
  //     versionContext.handles.setVersion(!versionContext.version)
  //   }}
  // >
  //   {versionContext.version && <span>dev</span>}
  //   {!versionContext.version && <span>prod</span>}
  // </CSSutility>)
}

const CSSutility = styled.div`
  cursor: pointer;
  position: fixed;
  bottom: 4px;
  left: 4px;
  width: 64px;
  font-size: 10px;
  text-align: center;
  z-index: 1000000;
  display: flex;
  justify-content: center;
`;
