import React, { Fragment, useState} from "react";
import {useHistory } from 'react-router-dom'
import { UIbutton } from "../uikit/Uikit";
// import { withRouter } from "react-router-dom";
import { useAuth } from "../contexts/auth";
import { UIinput } from "../uikit/UIinput";
import { LoginLayout } from "./LoginLayout";
import {ToastProvider, useToasts} from "react-toast-notifications";


export const LoginForm = ({create, forgot}) => {

  const history = useHistory();
 
  const authContext = useAuth();

  const [name, setName] = useState("");
  const [username, setUsername] = useState("")
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState("")
  const [completed, setCompleted] = useState(false)

  const {addToast} = useToasts();

  const onPressLogin = async (e) => {
    e.preventDefault();
    try {
      await authContext.handles.login({email, password})
      console.log("hi")
      history.push("/")
    } catch (err) {
      // setStatus(err.message)
      addToast(err.message, {
        appearance: "error", autoDismiss: true
      })
    }
  }

    const onPressForgot = () => {
      console.log("forgot password for email:" + email);
    };

    const onPressCreate = async () => {
      try {
        await authContext.handles.createAccount({
          name, username, email, password,
        })
        setCompleted(true)
        setStatus("Thank you for registration. Please check your email.")
      } catch (err) {
        setStatus(err.message)
        addToast(err.message, {
          appearance: "error", autoDismiss: true
        })
      }
    };

  const handleKeyPress = async (e) => {
    if(e.key === "Enter") {
      try {
        await authContext.handles.login({email, password})
        history.push("/");
      } catch (err) {
        // setStatus(err.message)
        addToast(err.message, {
          appearance: "error", autoDismiss: true
        })
      }
    }
    }


    return (
      <Fragment>
         <ToastProvider>
 <LoginLayout>

    <div className="flex vertical center middle">
      {!create && !forgot && (<div className="line line--lg  line--center">
        <h2>Log into your account</h2>
      </div>)}
      {create && (<div className="line line--lg line--center">
        <h2>Create account</h2>
      </div>)}
      {forgot && (<div className="line line--lg line--center">
        <h1>Forgot password</h1>
      </div>)}

      {status && (<div className="line line--center sm">
        <h5>{status}</h5>
      </div>)}

      <div className="line line--lg">


        {!completed && (<div className="line line--center">
          <UIinput id="email" label="Email"
            onChange={(value) => setEmail(value)}
            onEnter={handleKeyPress}
          />
        </div>)}

        {!completed && !forgot && <>
          <div className="line line--center">
            <UIinput id="password" password={true} label="Password"
              style={{ width: '100%' }}
              onChange={(value) => setPassword(value)}
            onEnter={handleKeyPress}	
            />
          </div>
        </>}

        {!completed && create && (<>
          <div className="line line--center">
            <UIinput id="name" label="Name and Last Name"
              onChange={(value) => setName(value)}   onEnter={handleKeyPress}/>
          </div>

          {/* <div className="line line--center">
            <UIinput id="username" label="Username"
            onChange={(value) => setUsername(value)}
              style={{ width: '100%' }}
          />
          </div> */}
        </>)}


        {!completed && !create && !forgot && (<>
          <div className="line line--center">
          <UIbutton
            center
              style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
              onClick={onPressLogin}
          > Log in </UIbutton>
          </div></>)}


        {!completed && create && (<>
          <div className="line line--center">
            <UIbutton
              center
              style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
              onClick={onPressCreate}>Submit</UIbutton>
          </div>
        </>)}


        {!completed && forgot && (<>
          <div className="line line--center">
            <UIbutton
              center
              style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
              onClick={onPressForgot}> Submit </UIbutton>
          </div>
        </>)}

      </div>


      {!completed && create && (<>
        <div className="line line--center">
          <div className="line line--center">
            <a href="/login" className="link link--text">Log in with existing account</a>
          </div>
        </div>
      </>)}



      {!completed && !create && !forgot && (<>
        <div className="line">
          <div className="c_m_section">
            <div className="x_login_subactions">
              <div>
                <a href="/createaccount" className="link link--text">I don't have an account</a>
              </div>
              <div>
                <a href="/forgotpassword" className="link link--text">Forgot password</a>
              </div>
            </div>
          </div>
        </div>
      </>)}




      {!completed && forgot && (<>
        <div className="line line--center">
          <a href="/login" className="link link--text">Back to Login</a>
        </div>
      </>)}


    </div>
 
  </LoginLayout>
  </ToastProvider> 
      </Fragment>
    )

}
