import {withRouter} from "react-router-dom";
import {LoginForm} from "../pages/LoginForm"
import {ToastProvider} from "react-toast-notifications";

const LoginInner = ({create, forgot}) => {
	return (
		<ToastProvider>
			<LoginForm create={create} forgot={forgot}/>
		</ToastProvider>
	);
};

export const Login = withRouter(LoginInner);
export const Login2 = LoginInner;