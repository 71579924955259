import videojs from "video.js";

import {QUALITY_OPTIONS} from "../../constants";
import QualitySelectorItem from "./QualitySelectorItem";

const MenuButton = videojs.getComponent("MenuButton");

class QualitySelectorMenu extends MenuButton {
  constructor(player, options = {}) {
    super(player, options);

    this.on(player, "updateSpeed", () => {
      this.triggerItemUpdate();
    });
  }

  buildCSSClass() {
    return `${MenuButton.prototype.buildCSSClass.call(
      this
    )} vjs-icon-cog vjs-quality-selector`;
  }

  triggerItemUpdate() {
    this.menuItems.forEach((item) => item.update());
  }

  createItems() {
    this.menuItems = [];

    for (const option of QUALITY_OPTIONS) {
      this.menuItems.push(
        new QualitySelectorItem(this.player_, {
          controller: this,
          label: option.label,
          fileName: option.fileName,
        })
      );
    }

    return this.menuItems;
  }
}

export default QualitySelectorMenu;
