import React, {useRef} from "react";
import styled from "styled-components";
import {UnderConstruction} from "./components/UnderConstruction";
import {Home} from "./components/Home";
import {APIProvider} from "./contexts/api";
import {AuthProvider} from "./contexts/auth";
import {DataProvider} from "./contexts/data";
import {VersionProvider} from "./contexts/version";
// import {ToastProvider, useToasts} from "react-toast-notifications";

function App() {
	const body = useRef();

	// const FormWithToasts = () => {
	// 	const {addToast} = useToasts();

	// 	const onSubmit = async value => {
	// 		const {error} = await dataPersistenceLayer(value);

	// 		if(error){
	// 			addToast(error.message, { appearance:'error'

	// 			});
	// 		} else {
	// 			addToast('Saved Successfully', {appearance: 'success'})
	// 		}
	// 	}
	// 	return <form onSubmit={onSubmit}>
	// 		//insert form here
	// 	</form>
	// }
	//wrapper look into

	return (
		<div className="App b00" ref={body}>
			<UnderConstruction mobileh mobile/>
      <CSSutility>
        {/* Deploying indicator */}
      </CSSutility>
			<APIProvider>
				<AuthProvider>
					<DataProvider>
						<VersionProvider>
							<Home/>
						</VersionProvider>
					</DataProvider>
				</AuthProvider>
			</APIProvider>
		</div>
	);
}

const CSSutility = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1000000;
  display: flex;
  justify-content: flex-end;
`;

export default App;
