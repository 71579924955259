import React, { useState, useEffect, Fragment, useRef } from "react";

import styled, { css, ThemeProvider } from "styled-components";
import './Uikit.css';




const defaultColors = {
  color: '#000000',
  accent: '#06a19a',
  background: '#f7f7f7',
  boxShadow: '#06a19a',
  borderRadius: '3px',
}


export const UIinput = ({
  compact,
  id,
  defaultValue,
  style,
  label,
  extra,
  onChange: onChange = () => void (0),
  onEnter: onEnter = () => void (0),
  children,
  autofocus,
  newStyle = true,
  placeholder,
  autocomplete,
  monospace,
  password,
  width,
  theme = {
    ...defaultColors
  }
}) => {
  const [focused, setFocused] = useState(false);
  const [val, setVal] = useState(defaultValue || '');
  const [plc, setPlc] = useState(placeholder);

  theme = { ...defaultColors, ...theme }

  let uniq = useRef(null);
  useEffect(() => {
    if (val != '' && autofocus) {
      uniq.current.focus();
      setFocused(true);
    }
    onChange(val)
  }, [val]);
  // useEffect(() => {
  //   handle(val);
  //   console.log('val = ', val);
  // }, [val]);
  // InputM2.handleClickOutside = evt => {
  //   setFocused(false);
  // };

  const getPasswordSymbol = (val) => {
    const symbol = '◼︎';
    return Array.apply(null, Array(val.length)).map(one => symbol)
  }


  return (
    <div
      // filled={!!val ? true : false}
      // focused={focused}
      style={{
        display: 'inline-block',
        position: 'relative',
        width: width ? width : '100%',
        ...style,
      }}
    >

      <Fragment>
        {!compact && !!label && <CssLabel
          filled={!!val ? true : false}
          focused={focused}
          placeholder={plc ? plc : ''}
        >
          {label}
        </CssLabel>}

        {!!extra && <CssExtra
          filled={!!val ? true : false}
          focused={focused}
          placeholder={plc ? plc : ''}
        >
          {extra}
        </CssExtra>}

        <div style={{ position: 'relative' }}>
          <CssInput
            width={width}
            compact={compact}
            id={id}
            type={password ? "password" : "text"}
            theme={theme}
            monospace={password ? true : monospace}
            focused={focused}
            filled={(val && val !== '') ? true : false}
            placeholder={plc}
            ref={uniq}
            autocomplete="current-password"
            value={val}
            onFocus={() => {
              setFocused(true);
              setPlc(null)
            }}
            onBlur={() => {
              setFocused(false)
              setPlc(placeholder)
            }}
            onChange={(e) => setVal(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' ? onEnter(e) : null}
          />
        </div>
      </Fragment>
    </div>);
};

export const CssExtra = styled.div`
  position: absolute;
  right: 8px;
  top: 4px;
  bottom: 4px;
  z-index: 1;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colorAccent};
`

const CSSpassword = styled.div`
  position: absolute;
  padding: 22px 12px 0px 12px;
  top:0;
`

export const CssLabel = styled.div`
  pointer-events: none !important;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.014em;
  transition: all 50ms ease-in-out;
  opacity: 0.7;
  position: absolute;
  z-index:1;
  left: 12px;
  font-size: 12px;

  ${({ focused, filled, placeholder }) => !filled && !placeholder && !focused && css`
    font-size: 16px;
    top: 2px;
    left: 12px;
    top: 30%;
  `}
  ${({ focused, filled, placeholder }) => !filled && placeholder && !focused && css`

  `}
  ${({ focused, filled }) => !filled && focused && css`

  `}
  ${({ focused, filled }) => filled && !focused && css`

  `}
  ${({ focused, filled }) => filled && focused && css`

  `}
`


export const CssInput = styled.input`
  color: ${({ theme, password }) => password ? 'rgba(0,0,0,0)' : theme.colorAccent};
  background:  ${({ theme }) => theme['background']};

  border: 0px solid transparent;
  box-shadow: inset 0px 0px 0px 0px transparent;

  font-size: 16px;
  width: ${({ width }) => width ? width : '100%'};
  line-height: 24px;
  letter-spacing: -0.013em;
  position: relative;
  outline: none;
  padding: ${({ compact }) => compact ? '6px 12px 6px 12px' : '20px 12px 8px 12px'};
  box-sizing: border-box;
  transition: background-color 150ms ease-in-out 0s, border-color 150ms ease-in-out 0s, box-shadow 150ms  ease-in-out 0s;
  border-radius: ${({ theme }) => theme['borderRadius']};
  border: 1px solid transparent;
  /* color: rgb(232, 232, 232); */
  /* &:['type'] = text; */

  height: ${({ compact }) => compact ? '36px' : '52px'}





  ${({ focused, filled }) => !focused && !filled && css`
    background:  ${({ theme }) => theme['background']};
  `}
  ${({ focused, filled, placeholder }) => !focused && placeholder && !filled && css`
    background:  ${({ theme }) => theme['background']};
    color: ${({ theme }) => theme['color']};
  `}
  ${({ focused, filled }) => !focused && filled && css`
    background:  ${({ theme }) => theme['background']};
  `}

  ${({ monospace }) => monospace && css`
    font-family: 'Space Mono', 'PT Mono', monospace;
    letter-spacing: 0.05em;
    font-weight: 600;
  `}

  &:hover {
    background:  ${({ theme }) => theme['background']};
    box-shadow: inset 0px 0px 0px 1px ${({ theme }) => theme['boxShadow']};
  };

  &:focus {
    background:  ${({ theme }) => theme['background']};
    color: ${({ theme }) => theme.colorAccent};
    border: 1px solid ${({ theme }) => theme.colorAccent};
    box-shadow: inset 0px 0px 0px 1px ${({ theme }) => theme['accent']};
  };
`







