export const MP4_SOURCE_TYPE = "video/mp4";
export const SOURCE_EXTENSION = ".mp4";
export const MAX_BROWSER_SPEED = 5;

export const PLAYER_SETTINGS = {
  controls: true,
  responsive: true,
  aspectRatio: "16:9",
  playsinline: true /* hides iOS native controls in non-fullscreen mode */,
  userActions: {
    hotkeys: true,
  },
  controlBar: {
    children: [
      "playToggle",
      "volumePanel",
      "currentTimeDisplay",
      "timeDivider",
      "durationDisplay",
      "progressControl",
      "customControlSpacer",
      "pictureInPictureToggle",
      "fullscreenToggle",
    ],
  },
};

export const IDENTIFIER_1X = "1x-high";
export const IDENTIFIER_MEDIUM = "1x-medium";
export const IDENTIFIER_LOW = "1x-low";
export const IDENTIFIER_10X = "10x";
export const IDENTIFIER_25X = "25x";
export const IDENTIFIER_50X = "50x";
export const IDENTIFIER_100X = "100x";

export const SPEED_OPTIONS = [
  { label: "100x", factor: 100 },
  { label: "50x", factor: 50 },
  { label: "25x", factor: 25 },
  { label: "10x", factor: 10 },
  { label: "5x", factor: 5 },
  { label: "2x", factor: 2 },
  { label: "1x", factor: 1 },
  { label: "0.5x", factor: 0.5 },
];

export const QUALITY_OPTIONS = [
  { label: "High", fileName: IDENTIFIER_1X },
  { label: "Medium", fileName: IDENTIFIER_MEDIUM },
  { label: "Low", fileName: IDENTIFIER_LOW },
];
